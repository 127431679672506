import React, { useEffect, useState } from "react";
import { callBlue, locationBlue, ticketBlue } from "../../assets/icons";

const ContactCard = ({icon, title, desc, direction}) => <div className="contact-card" style={ direction ? { width: "70%", minWidth: "30rem", marginBottom: "2rem" } : {}}>
  <div className="contact-card-icon">
    <img src={icon}/>
  </div>
  <div className="contact-card-text">
    <h2>{title}</h2>
    <p>{desc}</p>
  </div>
</div>

export default function HomePage(props) {

  return <div className="contact-us-container">
        {!props.hideLabel && <div className="contact-us-text">
            <h2>Contact Us</h2>
        </div>}
        <div className="contact-card-container" style={ props.direction ? { flexDirection: props.direction } : {}}>
            <ContactCard direction={props.direction} icon={callBlue} title="We are now available" desc="Call +(303)7465873 for contact with us"/>
            <ContactCard direction={props.direction} icon={ticketBlue} title="Chat now" desc="Book your location for your boat parking and live chat with us"/>
            <ContactCard direction={props.direction} icon={locationBlue} title="Location" desc="3891 Ranchview Dr. Richardson, California 62639"/>
        </div>
    </div>
}
