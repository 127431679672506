import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, TwoScreenLayout } from "../../../components";
import SyncLoader from "react-spinners/SyncLoader";
import OtpInput from "./OtpInput";

export default function OtpScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleSendOtp = () => {
    setIsLoading(true);
    toast.success("OTP sent successfully!"); 
    setTimeout(() => {
      setIsLoading(false);
      navigate("/resetPassword"); 
    }, 2000);
  };

  return (
    <TwoScreenLayout height="115vh" containerHeight="115vh">
      <h1>Enter OTP</h1>
      <h1 className="forget-description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
      </h1>
      <OtpInput length={4} onChange={handleOtpChange} />
      <h5 className="resend-code">You can resend the code in 56 seconds</h5>
      <h5 className="resend-code">Resend code</h5>

      <Button
        disabled={!otp}
        type="primary"
        classes="w-72p sm-mt-2"
        onClick={() => {
          handleSendOtp();
          // navigate("/otpScreen");
        }}
      >
        {isLoading ? <SyncLoader color="white" /> : "Send OTP"}
      </Button>
    </TwoScreenLayout>
  );
}
