import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from "react-router-dom";
import { fireAuth, fireDB } from '../../../firebase'
import { apple,facebook, google } from "../../../assets/icons";
import { Button, SocialLogin, TextInput, PasswordInput, TwoScreenLayout } from "../../../components";
import './login.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";
import { authErrors } from "../../../globalData"
import SyncLoader from "react-spinners/SyncLoader";


const { instance: auth, signInWithEmailAndPassword } = fireAuth
const { db, collection, addDoc, query, where, getDocs } = fireDB

const logIn = async (setIsLoading, navigate, email, loginInFunction=async()=>null) => {
    setIsLoading(true)
    try {
        const user = await loginInFunction()
        const userBoatQ = await getDocs(query(collection(db, "Boat"), where("email", '==', (user.email ?? email).toLowerCase())))
        const userPortQ = await getDocs(query(collection(db, "profile"), where("email", '==', (user.email ?? email).toLowerCase())))
        let userPort;
        let userBoat;
        userBoatQ.forEach(x => userBoat = x.data())
        userPortQ.forEach(x => userPort = x.data())
        if(userPort) {
            navigate("/dashboard" ,  { replace: true })
            return
        }
        if(userBoat) {
            navigate("/" ,  { replace: true })
            return
        }
        else navigate("/registerBoat")
    } catch (e) {
        console.log(e)
        toast.error(authErrors[e.code.replace("auth/", "")] ?? "An error has occured")
    }
    setIsLoading(false)
}

export default function HomePage() {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    return (
        <TwoScreenLayout height="115vh" containerHeight="115vh">
            <h1>Login</h1>
            <div className="social-button">
            <SocialLogin label="Google" icon={google} classes="sm-none" onLogin={({user}) => logIn(setIsLoading, navigate, email, async () => user)}/>
            <SocialLogin label="Apple" icon={apple} classes="sm-none" onLogin={({user}) => logIn(setIsLoading, navigate, email, async () => user)}/>
            <SocialLogin label="Facebook" icon={facebook} classes="sm-none" onLogin={({user}) => logIn(setIsLoading, navigate, email, async () => user)}/>
            </div>
            <div className="separator mb-2"></div>
            <TextInput containerStyle="mb-2 register-input-container" inputStyle="register-input" label="Email*" value={email} onChange={setEmail}/>
            <TextInput containerStyle="mb-2 register-input-container" inputStyle="register-input" label="Password" value={password} onChange={setPassword}/>
            <div className="forgot-password">
            <div className="have-an-account-prompt">
                <span>New to NaticalPay?</span>
                <span className="color-blue" style={{ cursor: 'pointer' }} onClick={() => navigate("/account-type")}>Register</span>
            </div>
            <div className="">
                <span className="color-blue forgot-password-text" style={{ cursor: 'pointer' }} onClick={() => navigate("/forgetPassword")}>Forgot Password?</span>
            </div>
            </div>

            <Button disabled={!email || !password} type="primary" classes="w-72p sm-mt-2" onClick={() => logIn(setIsLoading, navigate, email, () => signInWithEmailAndPassword(auth, email.toLowerCase(), password))}>{isLoading ? <SyncLoader color="white"/> : "Login"}</Button>
            <SocialLogin label="Google" icon={google} classes="sm-only-flex" onLogin={({user}) => logIn(setIsLoading, navigate, email, async () => user)}/>
            <SocialLogin label="Apple" icon={apple} classes="sm-only-flex" onLogin={({user}) => logIn(setIsLoading, navigate, email, async () => user)}/>
            <SocialLogin label="Facebook" icon={facebook} classes="sm-only-flex" onLogin={({user}) => logIn(setIsLoading, navigate, email, async () => user)}/>
        </TwoScreenLayout>
    );
}
