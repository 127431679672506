import * as authHooks from 'react-firebase-hooks/auth';
import * as dbHooks from 'react-firebase-hooks/firestore';
import { initializeApp } from "firebase/app";
import * as auth from "firebase/auth";
import * as dbModule from "firebase/firestore";
import * as storageModule from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyD5I2Q6lqL0SQ09rQ6K898U1xDjo8Sib_o",
    authDomain: "nauticalpay.firebaseapp.com",
    databaseURL: "https://nauticalpay-default-rtdb.firebaseio.com",
    projectId: "nauticalpay",
    storageBucket: "nauticalpay.appspot.com",
    messagingSenderId: "258956224523",
    appId: "1:258956224523:web:38c08739a5d33613cb7f0e",
    measurementId: "G-G67RP8K0KS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const instance = auth.getAuth(app)
const db = dbModule.getFirestore(app);
const storage = storageModule.getStorage(app);

const fireAuth = {
    ...auth,
    ...authHooks,
    instance,
}

const fireDB = {
    firestore: dbModule,
    ...dbModule,
    ...dbHooks,
    db,
}

const fireStorage = {
    storage,
    ...storageModule,
}

export {
    fireAuth,
    fireDB,
    fireStorage,
}