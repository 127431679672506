import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { uncle, camera } from "../../assets/icons";
import { NavBar, BookingCard, Button, SearchCard, ResultCard, Footer } from "../../components";

export default function HomePage(props) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className="alpha-dashboard-main_container">
      <NavBar loaderValue={(data) => setIsLoading(data)} />
      <div className="update-profile-container">
            <div className="update-profile-menu">
                <BookingCard options={["Profile", "Boats"]} default={window.location.href.toLowerCase().includes("boat") ? "boats" : "profile"} onMenuChange={text => {
                  if(text == "profile") return navigate("/updateProfile")
                  if(text == "boats") return navigate("/updateBoats")
                }}/>
            </div>
            <div className="mein-section-container">
                {props.children}
            </div>
      </div>
      <Footer/>
    </div>
  );
}
