import React, { useState } from "react";
import { NavBar, Button, ContactUs, Footer, TextInput, TextArea } from "../../../components";
import './contactUs.css'

export default function HomePage() {
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    return (
        <div className="alpha-dashboard-main_container">
            <NavBar loaderValue={(data) => setIsLoading(data)} />
            <div className="about-page-about-container">
                <div className="about-page-about-container-content">
                    <h1>Contact NauticalPay</h1>
                    <p>Our mission is to make life on the water easier for everyone. To that end, should you have any questions on our service or your particular booking our dedicated service team will be on hand to assist.</p>
                    <ContactUs direction="column" hideLabel={true}/>
                </div>
                <div className="about-page-about-container-form">
                    <h1>Contact Us</h1>
                    <TextInput inputStyle="fix-rem" containerStyle="mb-2" labelStyle="mb-half" label="Name*" onChange={setName} value={name}/>
                    <TextInput inputStyle="fix-rem" containerStyle="mb-2" labelStyle="mb-half" label="Email" onChange={setEmail} value={email}/>
                    <TextInput inputStyle="fix-rem" containerStyle="mb-2" labelStyle="mb-half" label="Subject" onChange={setSubject} value={subject}/>
                    <TextArea inputStyle="fix-rem" containerStyle="mb-5" labelStyle="mb-half" label="Message" onChange={setMessage} value={message}/>
                    <Button type="primary">Submit</Button>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
