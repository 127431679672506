import React from 'react'
import './textInput.css'
import { fireStorage } from '../../firebase';

const { storage, ref, uploadBytes, getDownloadURL } = fireStorage

const handleUpload = f => ev => {
    console.log("HI!!!")
    const file = ev.target.files[0];
    const reader = new FileReader()
    reader.onload = () => f(reader.result)
    reader.readAsDataURL(file)
    if (file) {
        const storageRef = ref(storage, `uploads/${Math.random()*10000 + 10000}-${file.name}`);
        uploadBytes(storageRef, file)
          .then((snapshot) => {
            // Upload complete, do something here (e.g., display a success message)
            console.log('Upload complete');
            getDownloadURL(storageRef)
            .then((url) => {
              f(url);
              console.log('Download URL:', url);
            })
            .catch((error) => {
              console.error('Error getting download URL:', error);
            });
          })
          .catch((error) => {
            console.error(error);
          });
    } else {
        console.error('No file selected');
    }  
}

const ImageInput = ({label, inputStyle, labelStyle, containerStyle, onChange}) => {
    return <div className={`text-input ${containerStyle}`}>
        <label className={labelStyle}>{label}</label>
        <input className={inputStyle} type="file" onChange={handleUpload(url => (onChange ?? (() => {}))(url))}/>
    </div>
}

export default ImageInput
export { handleUpload }
