import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fireAuth, fireDB } from '../firebase'
import useGetDocs from "./useGetDocs";
const { instance: auth, useAuthState } = fireAuth
const { db, collection, doc, query, where, setDoc, getDocs, onSnapshot } = fireDB

const getRanges = xs => xs.map(x => [x.bookingDetail.time.arrivalTime, x.bookingDetail.time.departureTime])

const useGetDoc = portId => {
    const [boatBookings] = useGetDocs("Bookings", ["boatId", "==", "@id"])
    const [portBookings] = useGetDocs("Bookings", ["portId", "==", portId])

    const bookings = [...(portBookings ?? []), ...(boatBookings ?? [])]

    console.log("Bookingsssss", bookings)

    return x => {
        if(bookings?.length) {
            return getRanges(bookings).map(([start, end]) => x >= start && x <= end).reduce((a, b) => a || b, false)
        }
        else if(bookings) return false 
        else return false
    }
}

export default useGetDoc;