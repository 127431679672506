import React from "react";
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube } from "../../assets/icons";
import { TimePicker, DatePicker, Space } from 'antd';

const { RangePicker } = DatePicker;
const locationImage = location
const calendarImage = calendar

const HomeSearchField = ({type, label, placeholder, value, onChange, image, input, inputStyle}) => {
    return <div className="home-search-field">
      <div className="home-search-field-text">{label}</div>
      <div className="home-search-field-input" style={inputStyle}>
        {input ? input : <input type={type} style={image ? {
          background: `url("${image}"), #F1F1F1`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: 'center',
          backgroundPositionX: '5px',
          paddingLeft: '35px',
        } : {}} placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} />}
      </div>
    </div>
}

const SearchCard = props => {
    return <div className={`home-search-container ${props.containerClasses}`}>
        <div className={`home-search-card ${props.cardClasses}`}>
          <HomeSearchField type="text" label={"Location"} placeholder={"Search"} image={locationImage} value={props.location} onChange={props.setLocation}/>
          <HomeSearchField type="text" label={"Coordinates"} placeholder={"Add Here..."} value={props.coordinates} onChange={props.setCoordinates}/>
          <HomeSearchField type="date" label={"Arrival/Departure Date"} input={<RangePicker
              className="home-search-field-input-dates"
              inputStyle={{
                width: "200px",
              }}
              suffixIcon={""}
              cellRender={(current, info) => {
                if (info.type !== 'date') return info.originNode;
                const style = {}
                if (current.date() === 1) {
                  style.border = '1px solid #1677ff';
                  style.borderRadius = '50%';
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
            />} placeholder={`${new Date().toLocaleDateString()} | ${new Date(Date.now()+24*60*60*1000).toLocaleDateString()}`} image={calendarImage} value={props.date} onChange={props.setDate}/>
            <HomeSearchField type="date" label={"Time"} input={<TimePicker.RangePicker
              className="home-search-field-input-times"
              inputStyle={{
                width: "200px",
              }}
              suffixIcon={""}
              cellRender={(current, info) => {
                if (info.type !== 'date') return info.originNode;
                const style = {}
                if (current.date() === 1) {
                  style.border = '1px solid #1677ff';
                  style.borderRadius = '50%';
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
            />} placeholder={`${new Date().toLocaleDateString()} | ${new Date(Date.now()+24*60*60*1000).toLocaleDateString()}`} image={clock} value={props.date} onChange={props.setDate}/>
          {props.searchButton && <div onClick={() => props.onSearch && props.onSearch()} className="home-search-card-button">
            <img src={search}/>
          </div>}
        </div>
    </div>
}

export default SearchCard