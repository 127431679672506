import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { logo, uncle, threeLines, arrowDownGrey } from '../../assets/icons'
import { fireAuth } from '../../firebase'
import './navBar.css'
import { useDispatch, useSelector } from "react-redux";
import { activeTab } from '../../redux/Slices/activeTabSlice'
import { accessToken, refreshToken, userData } from '../../redux/Slices/userDataSlice';
import useGetDoc from '../../hooks/useGetDoc';

const { instance: auth, signOut, signInWithEmailAndPassword, useAuthState } = fireAuth

const MenuItem = ({url, text, onClick, classes}) => {
    const navigate = useNavigate()

    return <div className={`navbar-item ${classes} ${url === window.location.pathname ? 'navbar-active' : ''}`} onClick={() => {
        if(onClick) onClick(url)
        else navigate(url)
    }}>{text}</div>
}

export default function NavBar(props) {

    const disPatch = useDispatch();
    const navigate = useNavigate();
    const [menuOpened, setMenuOpened] = useState(false)
    const [profileMenuOpened, setProfileMenuOpened] = useState(false)
    const [rerender, setRerender] = useState(false)
    const [options, setOptions] = useState([
        { label: "Profile", value: "profile", url: "/updateProfile" },
        { label: "Settings", value: "settings", url: "" },
        { label: "My Bookings", value: "myBookings", url: "/myBookings" },
        { label: "Logout", value: "logout", onClick: () => {
            setRerender(!rerender)
            signOut(auth)
        } },
    ])
    const [user, _, error] = useAuthState(auth)
    const [boat] = useGetDoc("Boat", ["email", "==", "#email"])

    const onClick = (type, value, data) => {
        disPatch(activeTab(value))
        navigate(type, { state: { screen: value, data: data } })
    }

    return <div className={`sticky-navbar-container ${profileMenuOpened ? "opened" : ""}`}>
        <div className='navbar'>
            <div className='navbar-controls'>
                <div className="logo-title">
                    <img src={logo}/>
                    <p>Nautical Pay</p>
                </div>
                <img className="three-lines" src={threeLines} onClick={() => setMenuOpened(!menuOpened)}/>
            </div>
            <div className={`navbar-content ${menuOpened ? 'opened' : ''}`}>
                <MenuItem url="/" text="Home"/>
                <MenuItem url="/locations" text="Locations"/>
                {user && <MenuItem url="/myBookings" text="My Bookings"/>}
                <MenuItem url="/about-us" text="About Us"/>
                <MenuItem url="/contact-us" text="Contact Us"/>
                {user ? <div className='logged-in-item'>
                    <p className="pointer" onClick={() => setProfileMenuOpened(!profileMenuOpened)}>Profile</p>
                    <img className='navbar-arrow-down pointer' src={arrowDownGrey} style={profileMenuOpened ? { transform: "rotate(180deg)" } : {}}  onClick={() => setProfileMenuOpened(!profileMenuOpened)}/>
                    <img className='navbar-uncle' src={boat?.profileImage ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRypgMMZNtrG9MPif00Vr2YHisnxeZjZrUrWA&usqp=CAU"} />
                    {profileMenuOpened && <div className='choice-input-options w-20r sm-none md-none'>
                        {options.map(opt => <>
                            <p className="pointer" onClick={() => {
                                setProfileMenuOpened(!profileMenuOpened)
                                if(opt.onClick) opt.onClick()
                                if(opt.url) navigate(opt.url)
                            }}>{opt.label}</p>
                            <div className='separator-choice'></div>
                        </>)}
                    </div>}
                </div> : <div className='login-item'>
                    <MenuItem url="/login" classes="navbar-login" text="Login"/>
                    <MenuItem url="/account-type" classes="navbar-register" text="Register"/>
                </div>}
                {profileMenuOpened && user && <div className='sm-only-flex md-only-flex profile-dropdown'>
                    {options.map(opt => <MenuItem url={opt.url} text={opt.label} onClick={opt.onClick}/>)}
                </div>}
            </div>
        </div>
    </div>
}