import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setParam } from "../../urlParams"
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube } from "../../assets/icons";

const preprocess = obj => {
    const newObj = { ...obj }
    delete newObj["rate"]
    return newObj
}

const ChildFullCard = props => {
    const navigate = useNavigate()
    const [result, setResult] = useState(props.result)

    return <div className={`banner-card-container ${props.containerClasses}`} style={{ height: "unset" }}>
        <div className="banner-inner-container">
            <div className={`banner-card ${props.cardClasses}`} style={{ flexDirection: 'column' }}>
                {!props.hideResultData && <div className="banner-inner-card">
                    <img className="banner-card-child" src={props.image ?? homeBoats}/>
                    <div className="card-content-children">
                        <div className={`banner-card-content ${props.contentClasses}`}>
                            <h2>{props.name}</h2>
                            <p>{props.desc}</p>
                            <h3>Coordinates</h3>
                            <div className="banner-card-coordinate-container">
                                <p className={props.coordinateFontClasses}>{typeof props.coordinates?.[0] == "string" ? props.coordinates?.[0] : `${`${props.coordinates?.[0]}`.split(".")[0]}*      ${`${props.coordinates?.[0]}`.split(".")[1]}`}</p>
                                <p className={props.coordinateFontClasses}>{typeof props.coordinates?.[1] == "string" ? props.coordinates?.[1] : `${`${props.coordinates?.[1]}`.split(".")[0]}*      ${`${props.coordinates?.[1]}`.split(".")[1]}`}</p>
                            </div>
                        </div>
                        <div className={`banner-card-price ${props.priceClasses}`}>
                            <h2 className={props.rateClasess}>{props.rate}</h2>
                            {props.bookNow && <div className="banner-card-book-now" onClick={() => navigate(`/bookNow?${setParam({port: JSON.stringify(preprocess(result))})}`)}>
                                <p className="banner-card-book-now-text">
                                    Book Now
                                </p>
                            </div>}
                            {props.moreDetails === undefined || props.moreDetails && <p className="banner-card-coordinate-container-details" onClick={() => navigate("/product-details")}>More Details</p>}
                        </div>
                    </div>
                </div>}
                {props.children}
            </div>
        </div>
    </div>
}

const ResultCard = props => {
    const navigate = useNavigate()
    const [result, setResult] = useState(props.result)

    return props.children ? <ChildFullCard {...props}>{props.children}</ChildFullCard> : <div className={`banner-card-container ${props.containerClasses}`}>
        <div className={`banner-card ${props.cardClasses}`}>
            <img src={props.image ?? homeBoats}/>
            <div className="card-content-children">
                <div className="banner-card-content">
                    <h2>{props.name}</h2>
                    <p>{props.desc}</p>
                    <h3>Coordinates</h3>
                    <div className="banner-card-coordinate-container">
                        <p className={props.coordinateFontClasses}>{typeof props.coordinates?.[0] == "string" ? props.coordinates?.[0] : `${`${props.coordinates?.[0]}`.split(".")[0]}*      ${`${props.coordinates?.[0]}`.split(".")[1]}`}</p>
                        <p className={props.coordinateFontClasses}>{typeof props.coordinates?.[1] == "string" ? props.coordinates?.[1] : `${`${props.coordinates?.[1]}`.split(".")[0]}*      ${`${props.coordinates?.[1]}`.split(".")[1]}`}</p>
                    </div>
                </div>
                <div className={`banner-card-price ${props.priceClasses}`}>
                    <h2 className={props.rateClasess}>{props.rate}</h2>
                    {props.bookNow && <div className="banner-card-book-now" style={{ cursor: props.booked ? "auto" : "pointer" }} onClick={() => props.booked ? null : navigate(`/bookNow?${setParam({port: JSON.stringify(preprocess(result))})}`)}>
                        <p className="banner-card-book-now-text">
                            {props.booked ? "Booked" : "Book Now"}
                        </p>
                    </div>}
                    {props.moreDetails === undefined || props.moreDetails && <p className="banner-card-coordinate-container-details" onClick={() => navigate(`/product-details?${setParam({
                        port: JSON.stringify(props.getPort(props.result))
                    })}`)}>More Details</p>}
                </div>
            </div>
        </div>
    </div>
    
}

export default ResultCard