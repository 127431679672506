import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fireAuth } from '../firebase'
const { instance: auth, useAuthState } = fireAuth

const useProtection = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)

    useEffect(() => {
        if(!loading && !user) navigate("/login")
    }, [user, loading])

    return []
};

export default useProtection;