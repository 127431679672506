import React from 'react'
import { BrowserRouter } from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar'

import './App.css'
import Navigation from './navigation/Navigation';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    return (
        <BrowserRouter>
            <SnackbarProvider>
                <Navigation />
            </SnackbarProvider>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </BrowserRouter>
    )
}

export default App
