import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useProtection from '../../../hooks/useProtection'
import useGetDoc from '../../../hooks/useGetDoc'
import { toast } from 'react-toastify';
import { fireAuth, fireDB } from '../../../firebase';
import { uncle, camera } from "../../../assets/icons";
import { handleUpload } from "../../../components/imageInput/imageInput";
import { ChoiceInput, TextInput, Button, SearchCard, Loader, ImageInput, UpdateScreen } from "../../../components";
import { requireNumber } from "../../../globalData";
import SyncLoader from "react-spinners/SyncLoader";

const { instance: auth, useAuthState } = fireAuth
const { db, collection, doc, setDoc, query, where, getDocs } = fireDB

const imgStyles = { width: "150px", height: "auto", border: "1px solid black", padding: "1rem" }

export default function HomePage() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [name, setName] = useState("")
  const [callSign, setCallSign] = useState("")
  const [license, setLicense] = useState("")
  const [emiratesId, setEmiratesId] = useState("")
  const [passport, setPassport] = useState("")
  const [user, loading, error] = useAuthState(auth)
  const [licenseNumber, setLicenseNumber] = useState("")
  const [serial, setSerial] = useState("")
  const [type, setType] = useState({
      label: "5m",
      value: "5m"
  })
  const [length, setLength] = useState("")
  const [beam, setBeam] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const [draft, setDraft] = useState("")
  const [boat, setBoat] = useGetDoc("Boat", ["email", '==', "#email"])

  const [types, setTypes] = useState([
      {
          label: "5m",
          value: "5m"
      },
      {
          label: "10m",
          value: "10m",
      },
      {
          label: "15m",
          value: "15m",
      },
      {
          label: "20m",
          value: "20m",
      },
      {
          label: "25m",
          value: "25m",
      },
  ])

  useProtection()

  useEffect(() => {
    if(boat) {
        setProfileImage(boat.profileImage)
        setName(boat.name)
        setName(boat.AboutBoat.boatName)
        setBeam(boat.AboutBoat.Beam)
        setLicenseNumber(boat.AboutBoat.licenseNumber)
        setCallSign(boat.AboutBoat.callSign)
        setDraft(boat.AboutBoat.draft)
        setType(boat.AboutBoat.BoatType)
        setLength(boat.AboutBoat.BoatWeight)
        setSerial(boat.AboutBoat.boatSerial)
        setEmiratesId(boat.docs.EmiratesIDUri)
        setLicense(boat.docs.LicenseUri)
        setPassport(boat.docs.PassportUri)
        setIsLoading(false)
    }
  }, [boat])

  return <UpdateScreen default="Profile">
        <input id="input" type="file" style={{ display: "none" }} onChange={handleUpload(url => setProfileImage(url))}/>
        <Loader loading={isLoading}/>
        <img className="uncle-img" src={profileImage ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRypgMMZNtrG9MPif00Vr2YHisnxeZjZrUrWA&usqp=CAU"} />
        <img className="camera-img" src={camera} onClick={() => document.getElementById("input").click()} />
        <TextInput containerStyle="mb-2" label="Boat Name" value={name} onChange={setName}/>
            <TextInput containerStyle="mb-2" label="Serial" value={serial} onChange={setSerial}/>
            <TextInput containerStyle="mb-2" label="Call Sign" value={callSign} onChange={setCallSign}/>
            <TextInput containerStyle="mb-2" label="License Number" value={licenseNumber} onChange={setLicenseNumber}/>
            <ChoiceInput containerStyle="mb-2" label="Type" options={types} value={type} onChange={setType}/>
            <TextInput containerStyle="mb-2" label="Length" value={length} onChange={requireNumber(setLength)}/>
            <TextInput containerStyle="mb-2" label="Beam" value={beam} onChange={requireNumber(setBeam)}/>
            <TextInput containerStyle="mb-2" label="Draft" value={draft} onChange={setDraft}/>
            <ImageInput containerStyle="mb-2" label="License" onChange={setLicense}/>
            <div className="given-container">
                <img src={license} style={imgStyles}/>
            </div>
            <ImageInput containerStyle="mb-2" label="Emirates Id" onChange={setEmiratesId}/>
            <div className="given-container">
                <img src={emiratesId} style={imgStyles}/>
            </div>
            <ImageInput containerStyle="mb-2" label="Passport" onChange={setPassport}/>
            <div className="given-container">
                <img src={passport} style={imgStyles}/>
            </div>
            <Button type="primary" classes={`w-40 sm-mt-2 mt-3 ${name && beam && licenseNumber && callSign && type && draft && length ? "" : "opacity-disabled"}`} onClick={async () => {
                setIsButtonLoading(true)
                try {
                    await setBoat({
                        ...(profileImage ? { profileImage } : {}),
                        AboutBoat: {
                            boatName: name,
                            Beam: beam,
                            licenseNumber,
                            callSign,
                            BoatType: type,
                            draft,
                            BoatWeight: length,
                            boatSerial: licenseNumber,
                        },
                        docs: {
                            EmiratesIDUri: emiratesId ? emiratesId : boat.docs.EmiratesIDUri,
                            LicenseUri: license ? license : boat.docs.LicenseUri,
                            PassportUri: passport ? passport : boat.docs.PassportUri,
                        },
                    })
                    toast.success("Boat Updated Successfully")
                } catch (e) {
                    toast.error("An error has occured")
                }
                setIsButtonLoading(false)
            }}>{isButtonLoading ? <SyncLoader color="white"/> : "Continue"}</Button>
    </UpdateScreen>
}
