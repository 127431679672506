import { MenuItem, Menu, Sidebar } from "react-pro-sidebar";
import React, { useState, useEffect } from "react";
import "./dashboard.css";
import {
  sidebarlogo,
  Home,
  Port,
  Bookings,
  Vector,
  TermsConditions,
  CustomerSupportIcon,
  Profile,
  Logout,
  notfication,
  menIcon,
  earning,
  bookDetail,
  menuBar,
} from "../../../assets/icons";
import { Loader } from "../../../components";
import HomeContent from "../homeContent/homeContent";
import PortContent from "../portContent/portContent";
import BookingsContent from "../bookingsContent/bookingsContent";
import AboutUsContent from "../aboutUsContent/aboutUsContent";
import {  useNavigate } from "react-router-dom";
import Term from "../term/term";
import Earning from "../Earning/earning";
import ProfileContent from "../profileContent/profileContent";
import CustomerSupport from "../customerSupport/customerSupport";
import BookingDetail from "../bookingDetail/bookingDetail";
import { fireAuth , fireDB} from "../../../firebase";
import useGetDocs from "../../../hooks/useGetDocs";

const { instance: auth, useAuthState } = fireAuth;
const { db, collection, doc, query, where, getDocs } = fireDB;

const LogoutContent = () => <div>Logout Content</div>;

const isLargeScreen = () => window.innerWidth >= 768;

export default function Dashboard() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("Home");
  const [isSidebarVisible, setIsSidebarVisible] = useState(isLargeScreen());
  const [user, loading, error] = useAuthState(auth);
  const [ports, setPorts] = useGetDocs("Port", ["userId", "==", user?.uid]);

  const [newBookings , setNewBookings] = useState(null);

  // const [results, setResults] = useGetDocs("NewBookings", ["portId", "==", ports[0]?.id]);

  useEffect(() => {
    console.log(ports);
    return;

    if(ports[0]?.id) {
    const getNewBookings = async () => {
      const newBookings = await getDocs(query(collection(db, "NewBookings"), where("portId", "==", ports[0]?.id)));
      setNewBookings(newBookings.docs.map(doc => ({id: doc.id, ...doc.data()})));
    }
    getNewBookings();
  }
  }, [ports]);


  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };
  const renderContent = () => {
    switch (selectedMenuItem) {
      case "Home":
        return <HomeContent ports={ports} bookingList={newBookings} />;
      case "Port":
        return <PortContent ports={ports} />;
      case "Bookings":
        return <BookingsContent portID={ports[0]?.id} />
      case "Bookings Detail":
        return <BookingDetail portID={ports[0]?.id}  />;
      case "About Us":
        return <AboutUsContent />;
      case "Earning":
        return <Earning />;
      case "Terms & Conditions":
        return <Term />;
      case "Customer Support":
        return <CustomerSupport />;
      case "Profile":
        return <ProfileContent />;
      case "Logout":
        return <LogoutContent />;
      default:
        return null;
    }
  };
  return (
    <div className="container">
      <div className="main-container">
        {/* <div className="header-right">
          <img
            src={menuBar}
            className="menu-bar"
            onClick={toggleSidebar}
            style={{ display: isSidebarVisible ? "none" : "block" }}
          />
        </div> */}
        <Sidebar
          width="282px !important"
          collapsedWidth="80px !important"
          collapsed={collapsed}
          className={`sidebar ${isSidebarVisible ? "" : "hidden"}`}
        >
          <div className="siderbar-container">
            <img src={sidebarlogo} className="sidebar-logo" />
          </div>
          <Menu
            menuItemStyles={{
              button: {
                padding: "9px 9px 9px 11px",
                gap: "12px",
                alignSelf: "start",
                color: "#9e9e9e",
                fontWeight: 400,
                fontSize: "16px",
                borderRadius: "12px",
                [`&:hover, &.ps-active`]: {
                  color: "#376fcc",
                  backgroundColor: "#ffffff !important",
                },
              },
            }}
            rootStyles={{ ["&>ul"]: { gap: "0.50px" } }}
            className="menu-container"
          >
            <MenuItem
              icon={<img src={Home} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("Home")}
            >
              Home
            </MenuItem>
            <MenuItem
              icon={<img src={Port} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("Port")}
            >
              Port
            </MenuItem>
            <MenuItem
              icon={<img src={Bookings} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("Bookings")}
            >
              Bookings
            </MenuItem>
            <MenuItem
              icon={<img src={bookDetail} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("Bookings Detail")}
            >
              Bookings Detail
            </MenuItem>
            <MenuItem
              icon={<img src={Vector} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("About Us")}
            >
              About Us
            </MenuItem>
            <MenuItem
              icon={<img src={TermsConditions} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("Terms & Conditions")}
            >
              Terms & Conditions
            </MenuItem>
            <MenuItem
              icon={<img src={earning} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("Earning")}
            >
              Earning
            </MenuItem>
            <MenuItem
              icon={<img src={CustomerSupportIcon} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("Customer Support")}
            >
              Customer Support
            </MenuItem>
            <MenuItem
              icon={<img src={Profile} className="menu-logo" />}
              className="menu-item"
              onClick={() => handleMenuItemClick("Profile")}
            >
              Profile
            </MenuItem>
            {!collapsed ? <div className="line" /> : null}
            <MenuItem
              icon={<img src={Logout} className="menu-logo" />}
              className="menu-item"
              onClick={() => {
                auth.signOut();
                navigate("/");
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
      <div className="main_content">
        <div className="header">
          <div className="header-left">
            <h1 className="header-title">Hello {user?.displayName}</h1>
            <p className="header-heading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
            </p>
          </div>
          <div className="header-right">
            <img src={notfication} />
            <img src={menIcon} className="menu-icon" />
          </div>
        </div>
        <Loader loading={loading} />
        <div className="content">{renderContent()}</div>
      </div>
    </div>
  );
}
