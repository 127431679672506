import React from "react";
import { chart } from "../../../assets/icons";
import { EarningCard } from "../../../components";
import "./earning.css";

export default function Earning() {
  const data = [
    {
      username: "John Doe",
      portName : "Port Harcourt",
      price : "$200",
    },
    {
      username: "John Doe",
      portName : "Port Harcourt",
      price : "$200",
    },
    {
      username: "John Doe",
      portName : "Port Harcourt",
      price : "$200",
    },
    {
      username: "John Doe",
      portName : "Port Harcourt",
      price : "$200",
    },
  ];
  return (
    <div className="earning-content">
      <div className="earning-header">
        <h1>Earning</h1>
      </div>
      <div className="earning-detail">
        <h4 className="total-text">total</h4>
        <h5 className="earning-amount">$560</h5>
      </div>
      <div className="earning-chart">
        <img src={chart} alt="chart" className="chart-image" />
      </div>
      <div className="earning-header">
        <h1 className="summary-title">Summary</h1>
      </div>
      <div className="earning-card">
        {data.map((item, index) => (
          <EarningCard
            key={index}
            username={item.username}
            portName={item.portName}
            price={item.price}
          />
        ))}
        </div>
    </div>
  );
}
