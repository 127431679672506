import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { fireAuth, fireDB } from "../../../firebase";
import { apple, facebook, google } from "../../../assets/icons";
import {
  Button,
  SocialLogin,
  TextInput,
  PasswordInput,
  TwoScreenLayout,
} from "../../../components";
// import './login.css'
import "./forgetPassword.css";
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";
import { authErrors } from "../../../globalData";
import SyncLoader from "react-spinners/SyncLoader";

const { instance: auth, signInWithEmailAndPassword } = fireAuth;
const { db, collection, addDoc, query, where, getDocs } = fireDB;

const logIn = async (
  setIsLoading,
  navigate,
  email,
  loginInFunction = async () => null
) => {
  setIsLoading(true);
  try {
    const user = await loginInFunction();
    console.log(user);
    const userBoatQ = await getDocs(
      query(
        collection(db, "Boat"),
        where("email", "==", (user.email ?? email).toLowerCase())
      )
    );
    const userPortQ = await getDocs(
      query(
        collection(db, "profile"),
        where("email", "==", (user.email ?? email).toLowerCase())
      )
    );
    let userPort;
    let userBoat;
    userBoatQ.forEach((x) => (userBoat = x.data()));
    userPortQ.forEach((x) => (userPort = x.data()));
    // console.log(userBoat, userPort)
    // console.log(userPort.accountType , "userPort.accountType")
    // return;
    if (userBoat) navigate("/");
    else navigate("/registerBoat");
  } catch (e) {
    console.log(e);
    toast.error(
      authErrors[e.code.replace("auth/", "")] ?? "An error has occured"
    );
  }
  setIsLoading(false);
};

export default function ForgetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  return (
    <TwoScreenLayout height="115vh" containerHeight="115vh">
      <h1>Forgot Password</h1>
      <h1 className="forget-description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
      </h1>
      <TextInput
        containerStyle="mb-2 register-input-container"
        inputStyle="register-input"
        label="Email"
        value={email}
        onChange={setEmail}
      />

      <Button
        disabled={!email}
        type="primary"
        classes="w-72p sm-mt-2"
        onClick={() => {
          logIn(setIsLoading, navigate, email, () =>
            signInWithEmailAndPassword(auth, email.toLowerCase())
          );
          navigate("/otpScreen");
        }}
      >
        {isLoading ? <SyncLoader color="white" /> : "Send OTP"}
      </Button>
    </TwoScreenLayout>
  );
}
