import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  logo,
  location,
  calendar,
  shower,
  toilets,
  fuel,
  homeBoats,
  example2,
  example3,
  example4,
  homeShips,
  arrow,
  ticketBlue,
  callBlue,
  locationBlue,
  facebook,
  instagram,
  linkedin,
  twitter,
  youtube,
  example1,
} from "../../../assets/icons";
import {
  NavBar,
  ContactUs,
  AppDownload,
  SearchCard,
  ResultCard,
  Footer,
  Button,
} from "../../../components";
import {
  FinancingStepFourAdmin,
  FinancingStepOneAdmin,
  FinancingStepThreeAdmin,
  FinancingStepTwoAdmin,
  Loader,
  SideBar,
  SubmitModel,
  TopBar,
} from "../../../components";
import "./productDetails.css";
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { getTotalDays } from "../../../globalData";
import { store } from "../../../redux/store";
import useDisabledDate from "../../../hooks/useDisabledDate";
import { DatePicker, TimePicker } from "antd";
import { setParam, getAllParams } from "../../../urlParams";

const { RangePicker } = DatePicker;
const { RangePicker: TimeRangePicker } = TimePicker;

const Facility = ({ icon, text }) => (
  <div className="details-page-perk-icon">
    <img src={icon} />
    <p>{text}</p>
  </div>
);

const SearchField = ({
  input,
  type,
  label,
  placeholder,
  value,
  onChange,
  image,
}) => {
  return (
    <div className="home-search-field">
      <div className="home-search-field-text">{label}</div>
      <div className="home-search-field-input">
        {input ? (
          input
        ) : (
          <input
            type={type}
            style={
              image
                ? {
                    background: `url("${image}"), #F1F1F1`,
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY: "center",
                    backgroundPositionX: "5px",
                    paddingLeft: "45px",
                    height: "45px",
                    width: "180px",
                  }
                : {
                    paddingLeft: "10px",
                    width: "180px",
                    height: "45px",
                  }
            }
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default function HomePage() {
  const navigate = useNavigate();
  const { port: portJSON } = getAllParams();
  const port = JSON.parse(portJSON);
  const disabledDate = useDisabledDate(port.id);
  const [time, setTime] = useState(null);
  const [nights, setNights] = useState(null);
  const [startDate, setStartDate] = useState(new Date(0).toISOString());
  const [endDate, setEndDate] = useState(new Date(0).toISOString());
  const [currentPic, setCurrentPic] = useState(port.PortPictures[0]);
  const [facilityContext, setFacilityContext] = useState({
    shower: { icon: shower, text: "Shower" },
    toilet: { icon: toilets, text: "Toilets" },
    fuel: { icon: fuel, text: "Refueling" },
  });
  const [product, setProduct] = useState({
    name: port.portName,
    rate: port.realRate,
    shortDescription: port.discription,
    coordinates: [port.lat, port.lng],
    image: homeShips,
    images: port.PortPictures.filter((url) => url != currentPic),
    description: <>{port.description ?? port.discription}</>,
    facilities: port.portfacilities,
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="alpha-dashboard-main_container">
      <NavBar loaderValue={(data) => setIsLoading(data)} />
      <div className="details-page-container">
        <div className="details-page-container-left">
          <img src={currentPic} />
          <div className="details-page-details-images">
            {product?.images?.map((image) => (
              <img
                className="pointer"
                onClick={() => setCurrentPic(image)}
                src={image}
              />
            ))}
          </div>
          <div className="details-page-details-section">
            <h2>Details</h2>
            <h3>Facilities</h3>
            <div className="details-page-perk-icon-container">
              {product?.facilities?.map((facility, index) => (
                <div key={index} className="facility-item">
                  <h3 className="facility-text">{facility}</h3>
                </div>
              ))}
            </div>
          </div>
          <div className="details-page-details-description">
            {product?.description}
          </div>
        </div>
        <div className="details-page-container-right">
          <div className="details-page-title-container">
            <h1>{product?.name}</h1>
            <div className="details-page-rate-container">
              <h2>${product?.rate}/</h2>
              <p>night</p>
            </div>
          </div>
          <p>{product?.shortDescription}</p>
          <h2>Coordinates</h2>
          <div className="details-page-coordinate-container">
            <p>{product?.coordinates?.[0]}</p>
            <p>{product?.coordinates?.[1]}</p>
          </div>
          <h1 className="details-page-availability">Availability</h1>
          <RangePicker
            disabledDate={disabledDate}
            onChange={([startDateDate, endDateDate]) => {
              const startDateTime = startDate.split("T")[1] ?? "00:00:00";
              const endDateTime = endDate.split("T")[1] ?? "00:00:00";
              setStartDate(
                `${
                  new Date(startDateDate).toISOString().split("T")[0]
                }T${startDateTime}`
              );
              setEndDate(
                `${
                  new Date(endDateDate).toISOString().split("T")[0]
                }T${startDateTime}`
              );
            }}
            cellRender={(current, info) => {
              if (info.type !== "date") return info.originNode;
              const style = {};
              if (current.date() === 1) {
                style.border = "1px solid #1677ff";
                style.borderRadius = "50%";
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          />
          <div className="details-page-time-nights-container">
            <SearchField
              type="text"
              label={"Time"}
              input={
                <TimeRangePicker
                  inputStyle={
                    {
                      // width: "200px",
                    }
                  }
                  onChange={([startDateTime, endDateTime]) => {
                    const startDateDate = startDate.split("T")[0];
                    const endDateDate = endDate.split("T")[0];
                    setStartDate(
                      `${startDateDate}T${
                        new Date(startDateTime).toISOString().split("T")[1]
                      }`
                    );
                    setEndDate(
                      `${endDateDate}T${
                        new Date(endDateTime).toISOString().split("T")[1]
                      }`
                    );
                  }}
                  cellRender={(current, info) => {
                    if (info.type !== "date") return info.originNode;
                    const style = {};
                    if (current.date() === 1) {
                      style.border = "1px solid #1677ff";
                      style.borderRadius = "50%";
                    }
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                />
              }
              image={calendar}
              value={time}
              onChange={setTime}
            />
            <SearchField
              type="text"
              label={"Nights"}
              value={(() => {
                const startDateX =
                  startDate instanceof Date
                    ? startDate.toISOString()
                    : startDate;
                const endDateX =
                  endDate instanceof Date ? startDate.toISOString() : endDate;
                return getTotalDays(startDateX, endDateX);
              })()}
              onChange={() => {}}
            />
          </div>
          <Button
            disabled={
              startDate == new Date(0).toISOString() &&
              endDate == new Date(0).toISOString()
            }
            type="primary"
            classes="details-page-button"
            onClick={() =>
              navigate(
                `/bookNow?${setParam({
                  port: JSON.stringify({
                    ...port,
                    realRate: port.ratePerNight,
                    startDate,
                    endDate,
                  }),
                })}`
              )
            }
          >
            Book Now
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
