import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fireAuth, fireDB } from "../firebase";
const { instance: auth, useAuthState } = fireAuth;
const { db, collection, doc, query, where, setDoc, getDocs, onSnapshot } =
  fireDB;

const useGetDoc = (colName, q) => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (error) navigate("/login");
  }, [error]);

  useEffect(() => {
    const f = async () => {
      if (user) {
        if (q[2]?.startsWith("#")) q[2] = user[q[2].replace("#", "")];
        const userBoatQ = await getDocs(
          query(collection(db, colName), where(...q))
        );
        let userBoat;
        userBoatQ.forEach((x) => (userBoat = { id: x.id, ...x.data() }));
        setData(userBoat);
        if (userBoat && userBoat.id) {
          const docRef = doc(db, colName, userBoat.id);
          onSnapshot(docRef, (doc) => setData({ id: doc.id, ...doc.data() }));
        } else {
          console.log("userBoat is undefined or does not have an id");
        }
      }
    };
    f();
  }, [user]);

  const frontSetData = async (givenData) => {
    const docRef = doc(db, colName, data.id);
    setData({ ...data, ...givenData });
    await setDoc(docRef, givenData, { merge: true });
  };

  return [data, frontSetData];
};

export default useGetDoc;
