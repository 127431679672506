import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube } from "../../assets/icons";
import { NavBar, ResultCard, Button, Footer } from "../../components";

const Feature = props => <div className={`book-now-page-calculation-featue ${props.containerClasses}`}>
    {props.top && <h2>{props.top}</h2>}
    <div className="book-now-page-calculation-featue-price">
        <p className={props.firstClasses}>{props.first}</p>
        <h2 className={props.secondClasses}>{props.second}</h2>
    </div>
    <div className="separator w-all"></div>
</div>

export default function HomePage(props) {
    const navigate = useNavigate()
    const [product, setProduct] = useState({
        name: "Florida Port",
        desc: "Waterfront area with sandy beaches, family fun at Wild Wadi Waterpark & beach bars serving seafood. Waterfront area with sandy beaches, family fun at Wild Wadi Waterpark & beach bars serving seafood.",
        coordinates: [
            "12” 1’ 46.6320” N",
            "12” 1’ 46.6320” E",
        ],
        rate: <div className="rate-container">
            <h2>$180/</h2>
            <p>night</p>
        </div>
    })
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className="alpha-dashboard-main_container">
            <div className="book-now-page-book-now-container">
                <div className="book-now-page-book-now-content">
                    <ResultCard cardClasses="w-unset" containerClasses="mt-2" name={product.name} hideResultData={true} desc={product.desc} coordinates={product.coordinates} rate={product.rate} moreDetails={false}>
                        {props.children}
                    </ResultCard>
                </div>
            </div>
        </div>
    );
}
