import React, { useEffect, useState } from "react";
import { fireAuth, fireDB } from '../../../firebase';
import useProtection from '../../../hooks/useProtection';
import { handleUpload } from "../../../components/imageInput/imageInput";
import { authErrors } from "../../../globalData";
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from "react-router-dom";
import { uncle, camera } from "../../../assets/icons";
import { UpdateScreen, TextInput, Button, Loader, SearchCard, ResultCard, Footer } from "../../../components";
import SyncLoader from "react-spinners/SyncLoader";

const { instance: auth, useAuthState, updatePassword, reauthenticateWithCredential, EmailAuthProvider } = fireAuth
const { db, collection, doc, setDoc, query, where, getDocs } = fireDB

export default function HomePage() {
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [profileImage, setProfileImage] = useState("")
  const [startedChanging, setStartedChanging] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  useProtection()

  useEffect(() => {
    const f = async () => {
      if(user) {
        const userBoatQ = await getDocs(query(collection(db, "Boat"), where("email", '==', user.email)))
        let userBoat;
        userBoatQ.forEach(x => userBoat = {id: x.id, ...x.data()})
        console.log(userBoat)
        setProfileImage(userBoat.profileImage)
        setIsLoading(false)
      }
    }
    f()
  }, [user])

  return <UpdateScreen default="profile">
        <input id="input" type="file" style={{ display: "none" }} onChange={handleUpload(url => setProfileImage(url))}/>
        <Loader loading={isLoading}/>
        <img className="uncle-img" src={profileImage ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRypgMMZNtrG9MPif00Vr2YHisnxeZjZrUrWA&usqp=CAU"} />
        <img className="camera-img" src={camera} onClick={() => document.getElementById("input").click()} />
        <TextInput label="Old Password" containerStyle="mb-2" inputStyle="w-42r" value={oldPassword} onChange={setOldPassword}/>
        <TextInput error={startedChanging && newPassword.length < 6 ? "The passord must be greater then 6 characters" : ""} label="New Password" containerStyle="mb-2" inputStyle="w-42r" value={newPassword} onChange={text => {
          setNewPassword(text)
          setStartedChanging(true)
        }}/>
        <TextInput error={confirmNewPassword != newPassword ? "Confirmation password needs to match the new password" : ""} label="Confirm New Password" containerStyle="mb-2" inputStyle="w-42r" value={confirmNewPassword} onChange={setConfirmNewPassword}/>
        <Button type="primary" classes="mt-6" disabled={confirmNewPassword != newPassword || !newPassword || !confirmNewPassword || !oldPassword} onClick={async () => {
          setIsButtonLoading(true)
          try {
            const credential = EmailAuthProvider.credential(
              user.email,
              oldPassword
            )
            await reauthenticateWithCredential(user, credential)
            await updatePassword(user, newPassword)
            setOldPassword("")
            setNewPassword("")
            setConfirmNewPassword("")
            setStartedChanging(false)
            toast.success("Password Updated Successfully")
          } catch (e) {
            console.log(e)
            toast.error(authErrors[e.code?.replace("auth/", "")] ?? "An error has occured")
          }
          setIsButtonLoading(false)
        }}>{isButtonLoading ? <SyncLoader color="white"/> : `Update Password`}</Button>
    </UpdateScreen>
}
