import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { fireAuth, fireDB } from "../../../firebase";
import { Button, Loader } from "../../../components";
import {
  portBanner,
  earningBanner,
  bookingBanner,
  dollarBag,
  bookingIcon,
} from "../../../assets/icons";
import "./homeContent.css";

const { db, collection, doc, query, where, getDocs } = fireDB;

export default function HomeContent({ ports }) {
  const [newBookings, setNewBookings] = useState(null);

  useEffect(() => {
    console.log(ports);
    setTimeout(() => {
      if (ports[0]?.id) {
        const getNewBookings = async () => {
          const newBookings = await getDocs(
            query(
              collection(db, "NewBookings"),
              where("portId", "==", ports[0]?.id)
            )
          );
          setNewBookings(
            newBookings.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          );
        };
        getNewBookings();
      }
    }, 5000);
  }, [ports]);

  return (
    <div className="container">
      {ports?.map((port, index) => (
        <div className="port-container" key={index}>
          <div className="port-header">
            <h1>{port.portName}</h1>
          </div>
          <div className="">
            <img
              src={port.PortPictures[0]}
              alt="port-banner"
              className="port-banner"
            />
          </div>
          <div className="earning-container">
            <div className="earning-banner" style={{ position: "relative" }}>
              <img
                src={earningBanner}
                alt="earning-banner"
                className="earning-banner"
              />
              <div
                style={{
                  position: "absolute",
                  top: "20%",
                  left: "30%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                }}
              >
                <div className="earning-mainContainer">
                  <img
                    src={dollarBag}
                    alt="dollar-bag"
                    className="earning-icon"
                  />
                  <div className="earning-detail">
                    <p className="earning-text">
                      $
                      {newBookings?.reduce(
                        (acc, booking) => acc + booking.totalCost,
                        0
                      ) || 0}
                    </p>
                    <p className="earning-title">Earning</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="earning-banner" style={{ position: "relative" }}>
              <img
                src={bookingBanner}
                alt="booking-banner"
                className="earning-banner"
              />
              <div
                style={{
                  position: "absolute",
                  top: "20%",
                  left: "30%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                }}
              >
                <div className="earning-mainContainer">
                  <img
                    src={bookingIcon}
                    alt="dollar-bag"
                    className="earning-icon"
                  />
                  <div className="earning-detail">
                    <p className="earning-text">{newBookings?.length || 0}</p>
                    <p className="earning-title">Current Bookings</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="port-Description">
            <h1 className="port-desc-title">Port Description</h1>
            <h1 className="port-desc">{port.discription}</h1>
          </div>
          <div className="coordinates">
            <h1 className="port-desc-title">Coordinates</h1>
            <div className="coordinates-container">
              <h4 className="coordinates-title">{port.lat}</h4>
              <h4 className="coordinates-title">{port.lng}</h4>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
