import React from 'react'
import './textArea.css'

const TextArea = ({label, inputStyle, labelStyle, containerStyle, value, onChange}) => {
    return <div className={`text-input ${containerStyle}`}>
        <label className={labelStyle}>{label}</label>
        <textarea className={inputStyle} type="text" value={value} onChange={ev => (onChange ?? (() => {}))(ev.target.value)}></textarea>
    </div>
}

export default TextArea
