import React, { useState, useEffect } from 'react'
import { arrowDownGrey, checked, unchecked } from "../../assets/icons";

const Option = ({selected, setSelected, text}) => <div className="booking-card-option">
  <img src={selected == text.toLowerCase() ? checked : unchecked} className="pointer" onClick={() => selected == text.toLowerCase() ? setSelected("") : setSelected(text.toLowerCase())}/>
  <h2>{text}</h2>
</div>

const BookingCard = props => {

    const [menuOpened, setMenuOpened] = useState(true)
    const [options, setOptions] = useState(props.options)
    const [selectedVersion, setSelectedVersion] = useState((props.default ?? "current").toLowerCase())

    useEffect(() => {
    }, [selectedVersion])

    return <div className="booking-card">
        <div className="booking-card-heading">
            <h1>Bookings</h1>
            <img
                src={arrowDownGrey}
                className='pointer' 
                style={ menuOpened ? {
                    transform: "rotate(180deg)"
                } : {} } 
                onClick={() => setMenuOpened(!menuOpened)}
            />
        </div>
        {menuOpened && <div className="booking-card-options">
            {options.map(option => <Option text={option} selected={selectedVersion} setSelected={x => {
                setSelectedVersion(x);
                (props.onMenuChange ?? (() => {}))(x)
            }}/>)}
        </div>}
    </div>

}

export default BookingCard