import React, { useState } from "react";
import { BCard, Loader } from "../../../components";
import useGetDocs from "../../../hooks/useGetDocs";

export default function BookingsContent({portID}) {
  const [activeTab, setActiveTab] = useState("Current");
   const [results, setResults , loading] = useGetDocs("NewBookings", ["portId", "==", portID]);
  const currentDate = new Date();

  const tabContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderBottom: "1px solid #ccc",
    marginTop: "20px",
  };

  const tabStyle = {
    flex: 1,
    textAlign: "center",
    listStyle: "none",
    padding: "15px 30px",
    cursor: "pointer",
    borderBottom: "3px solid transparent",
    fontSize: "16px",
  };

  const activeTabStyle = {
    ...tabStyle,
    borderBottom: "3px solid #376FCC",
    fontWeight: "bold",
    color: "#376FCC",
  };

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  };

  const renderTab = (label) => (
    <li
      style={activeTab === label ? activeTabStyle : tabStyle}
      onClick={() => setActiveTab(label)}
    >
      {label}
    </li>
  );

  const filteredData = results?.filter((item) => {
    const departureTime = new Date(item.time.departureTime);
    return activeTab === "Current" ? departureTime >= currentDate : departureTime < currentDate;
  });

  const cancelBooking = results?.filter((item) => {
    const departureTime = new Date(item.time.departureTime);
    return activeTab === "Current" ? departureTime <= currentDate : departureTime > currentDate;
  });

  return (
    <div className="booking-content">
      <Loader loading={loading} />
      <div className="booking-container">
        <div className="booking-header">
          <h1>Bookings</h1>
        </div>
        <ul style={tabContainerStyle}>
          {renderTab("Current")}
          {renderTab("Past")}
        </ul>
        <div style={containerStyle}>
          {filteredData?.map((item) => (
            <BCard
              key={item.id}
              username={item.portUserName}
              durationFrom={new Date(item.time.arrivalTime).toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'short' })}
              durationTo={new Date(item.time.departureTime).toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'short' })}
              totalAmount={`$${item.totalCost}`}
              current={cancelBooking}
            />
          ))}
        </div>
      </div>
    </div>
  );
}