import React, { useEffect, useState } from "react";
import { apple, play } from "../../assets/icons";

const DownloadAppButton = ({icon, store}) => <div className="store-button">
  <div className="store-button-icon">
    <img src={icon}/>
  </div>
  <div className="store-button-text">
    <h3>Download on the</h3>
    <h2>{store}</h2>
  </div>
</div>

export default function HomePage() {

  return <div className="app-download-container">
        <h2>Download our App</h2>
        <div className="app-download-container app-icon-container">
            <DownloadAppButton icon={apple} store={"App Store"}/>
            <DownloadAppButton icon={play} store={"Play Store"}/>
        </div>
    </div>
}
