// src/OtpInput.js
import React, { useState } from 'react';

const OtpInput = ({ length, onChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));

  const handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (element.nextSibling && value) {
      element.nextSibling.focus();
    }

    onChange(newOtp.join(''));
  };

  const handleBackspace = (element, index) => {
    if (element.value === '' && index > 0) {
      const prevElement = element.previousSibling;
      if (prevElement) {
        prevElement.focus();
      }
    }
  };

  const inputStyle = {
    padding: '10px',
    marginRight: '6px',
    border: '1px solid #ced4da',
    boxShadow: 'none',
    borderRadius: '16px',
    width: '60px', 
    height: '60px', 
    textAlign: 'center',
    fontSize: '18px',
    outlineColor: '#495057',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}> 
      {otp.map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          style={inputStyle} // Apply the style
          value={otp[index]}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => e.key === 'Backspace' && handleBackspace(e.target, index)}
          onFocus={(e) => e.target.select()}
        />
      ))}
    </div>
  );
};

export default OtpInput;