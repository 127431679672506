import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { check } from "../../../assets/icons";
import { NavBar, Card, TextInput, Button, Footer } from "../../../components";
import './bookingDone.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";
import LocaleProvider from "antd/es/locale";

const Feature = props => <div className={`book-now-page-calculation-featue ${props.containerClasses}`}>
    {props.top && <h2>{props.top}</h2>}
    <div className="book-now-page-calculation-featue-price">
        <p className={props.firstClasses}>{props.first}</p>
        <h2 className={props.secondClasses}>{props.second}</h2>
    </div>
    <div className="separator w-all"></div>
</div>

export default function HomePage() {
    const navigate = useNavigate()
    const [cardName, setCardName] = useState("")
    const [cardNumber, setCardNumber] = useState("")
    const [expiry, setExpiry] = useState("")
    const [cvv, setCvv] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isPaypalSelected, setIsPaypalSelected] = useState(false)
    const [isCardSelected, setIsCardSelected] = useState(true)

    return (
        <div className="alpha-dashboard-main_container">
            <NavBar loaderValue={(data) => setIsLoading(data)} />
            <Card>
                <div className="payment-page-payment-container">
                    <div className="payment-page-text">
                        <h2>Booking Done</h2>
                    </div>
                    <div className="booking-done-page-check-container">
                        <img src={check}/>
                        <h2>Successfully Booked your desired Marina</h2>
                    </div>
                    <div className="booking-done-page-book-now-button-container">
                        <Button onClick={() => navigate("/myBookings")} type="primary" classes="w-72p">See My Bookings</Button>
                    </div>
                </div>
            </Card>
            <Footer/>
        </div>
    );
}
