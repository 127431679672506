import React, { useState, useEffect } from "react";
import {
  profileIcon,
  editIcon,
  email,
  mobile,
  lock,
  fluentBeach,
  locationoutline,
  beach,
  priceTag,
  photoselect,
  roundImage,
} from "../../../assets/icons";
import { Button } from "../../../components";
import { toast } from "react-toastify";
import useGetDocs from "../../../hooks/useGetDocs";
import { fireAuth, fireDB } from "../../../firebase";
import "./profileContent.css";

const { instance: auth, useAuthState } = fireAuth;
const { db, doc, addDoc, updateDoc, collection } = fireDB;
const ProfileDetail = ({ title, description, icon, altText, onEdit }) => (
  <div className="second-container">
    <div className="inner-container">
      <div className="inner-container-left">
        <img src={icon} alt={altText} className="icon-size" />
        <div className="left-container-header">
          <h4 className="left-title">{title}</h4>
          <h4 className="left-desc">{description}</h4>
        </div>
      </div>
      <div className="inner-container-right" onClick={onEdit}>
        <img src={editIcon} alt="edit-icon" className="icon-size " />
      </div>
    </div>
  </div>
);

const InputField = ({ label, value, onChange }) => (
  <input
    label={label}
    value={value}
    className="second-container"
    onChange={onChange}
  />
);

export default function ProfileContent() {
  const [images, setImages] = useState([]);
  const [user, loading, error] = useAuthState(auth);
  const [ports, setPorts] = useGetDocs("Port", ["userId", "==", user?.uid]);
  const [profile, setProfile] = useGetDocs("profile", [
    "userId",
    "==",
    user?.uid,
  ]);
  const [editMode, setEditMode] = useState({ active: false, field: null });
  const [editableFields, setEditableFields] = useState({
    displayName: "",
    email: "",
    phone: "",
  });
  const [editPort, setEditPort] = useState({ active: false, field: null });
  const [editableFieldsPort, setEditableFieldsPort] = useState({
    portName: "",
    location: "",
    lat: "",
    lng: "",
    ratePerNight: "",
    discription: "",
  });
  useEffect(() => {
    if (profile) {
      setEditableFields({
        displayName: profile[0]?.displayName,
        email: profile[0]?.email,
        phone: profile[0]?.phone,
      });
    }
    if (ports) {
      setEditableFieldsPort({
        portName: ports[0]?.portName,
        location: ports[0]?.Location,
        lat: ports[0]?.lat,
        lng: ports[0]?.lng,
        ratePerNight: ports[0]?.ratePerNight,
        discription: ports[0]?.discription,
      });
    }
  }, [profile, ports]);
  const [activeTab, setActiveTab] = useState("Personal");
  const handleEditClick = (field) => {
    setEditMode({ active: true, field });
  };
  const tabContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderBottom: "1px solid #ccc",
    marginTop: "20px",
  };

  const tabStyle = {
    flex: 1,
    textAlign: "center",
    listStyle: "none",
    padding: "15px 30px",
    cursor: "pointer",
    borderBottom: "3px solid transparent",
    fontSize: "16px",
  };

  const activeTabStyle = {
    ...tabStyle,
    borderBottom: "3px solid #376FCC",
    fontWeight: "bold",
    color: "#376FCC",
  };
  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
  };
  const renderTab = (label) => (
    <li
      style={activeTab === label ? activeTabStyle : tabStyle}
      onClick={() => setActiveTab(label)}
    >
      {label}
    </li>
  );

  return (
    <div className="profile-content">
      <div className="profile-container">
        <h1>Profile</h1>
      </div>
      <div>
        <ul style={tabContainerStyle}>
          {renderTab("Personal")}
          {renderTab("Port")}
        </ul>
        <div style={containerStyle}>
          <div>
            <img
              src={roundImage}
              alt="profile-icon"
              className="profile-image"
            />
          </div>
          {activeTab === "Personal" && (
            <>
              {editMode.active && editMode.field === "Name" ? (
                <>
                  <InputField
                    label="Name"
                    value={editableFields.displayName}
                    onChange={(e) =>
                      setEditableFields({
                        ...editableFields,
                        displayName: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <ProfileDetail
                  title="Name"
                  description={editableFields.displayName}
                  icon={profileIcon}
                  altText="profile-icon"
                  onEdit={() => handleEditClick("Name")}
                />
              )}
              {editMode.active && editMode.field === "Email" ? (
                <>
                  <InputField
                    label="Email"
                    value={editableFields.email}
                    onChange={(e) =>
                      setEditableFields({
                        ...editableFields,
                        email: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <ProfileDetail
                  title="Email"
                  description={user?.email}
                  icon={email}
                  altText="email-icon"
                  onEdit={() => handleEditClick("Email")}
                />
              )}
              {editMode.active && editMode.field === "Phone Number" ? (
                <>
                  <InputField
                    label="Phone Number"
                    value={editableFields.phone || ""}
                    onChange={(e) =>
                      setEditableFields({
                        ...editableFields,
                        phone: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <ProfileDetail
                  title="Phone Number"
                  description={editableFields.phone}
                  icon={mobile}
                  altText="mobile-icon"
                  onEdit={() => handleEditClick("Phone Number")}
                />
              )}
              <ProfileDetail
                title="Password"
                description="********"
                icon={lock}
                altText="lock-icon"
              />
            </>
          )}
          {activeTab === "Port" && (
            <>
              {editPort.active && editPort.field === "Port Name" ? (
                <>
                  <InputField
                    label="Port Name"
                    value={editableFieldsPort.portName}
                    onChange={(e) =>
                      setEditableFieldsPort({
                        ...editableFieldsPort,
                        portName: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <ProfileDetail
                  title="Port Name"
                  description={editableFieldsPort.portName}
                  icon={fluentBeach}
                  altText="fluent-beach"
                  onEdit={() => setEditPort({ active: true, field: "Port Name" })}
                />
              )}
              {editPort.active && editPort.field === "Location" ? (
                <>
                  <InputField
                    label="Location"
                    value={editableFieldsPort.location}
                    onChange={(e) =>
                      setEditableFieldsPort({
                        ...editableFieldsPort,
                        location: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <ProfileDetail
                  title="Location"
                  description={editableFieldsPort.location}
                  icon={locationoutline}
                  altText="location-outline"
                  onEdit={() =>
                    setEditPort({ active: true, field: "Location" })
                  }
                />
              )}
              {editPort.active && editPort.field === "Coordinates" ? (
                <>
                  <InputField
                    label="Lat"
                    value={editableFieldsPort.lat}
                    onChange={(e) =>
                      setEditableFieldsPort({
                        ...editableFieldsPort,
                        lat: e.target.value,
                      })
                    }
                  />
                  <InputField
                    label="Lng"
                    value={editableFieldsPort.lng}
                    onChange={(e) =>
                      setEditableFieldsPort({
                        ...editableFieldsPort,
                        lng: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <ProfileDetail
                  title="Coordinates"
                  description={`Lat: ${editableFieldsPort.lat}, Lng: ${editableFieldsPort.lng}`}
                  icon={beach}
                  altText="beach"
                  onEdit={() =>
                    setEditPort({ active: true, field: "Coordinates" })
                  }
                />
              )}
              {editPort.active && editPort.field === "Rate Per Night" ? (
                <>
                  <InputField
                    label="Rate Per Night"
                    value={editableFieldsPort.ratePerNight}
                    onChange={(e) =>
                      setEditableFieldsPort({
                        ...editableFieldsPort,
                        ratePerNight: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <ProfileDetail
                  title="Rate Per Night"
                  description={`$ ${editableFieldsPort.ratePerNight}`}
                  icon={priceTag}
                  altText="price-tag"
                  onEdit={() =>
                    setEditPort({ active: true, field: "Rate Per Night" })
                  }
                />
              )}
              {editPort.active && editPort.field === "Description" ? (
                <>
                  <InputField
                    label="Description"
                    value={editableFieldsPort.discription}
                    onChange={(e) =>
                      setEditableFieldsPort({
                        ...editableFieldsPort,
                        discription: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
              <div className="third-container">
                <div className="inner-container">
                  <div className="inner-container-left">
                    <img src={beach} alt="photo-select" className="icon-size" />
                    <div className="left-container-header">
                      <h4 className="left-title">Description</h4>
                      <h4 className="left-desc">
                        {editableFieldsPort.discription}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <img src={editIcon} alt="edit-icon" className="icon-size" onClick={() => setEditPort({ active: true, field: "Description" })} />
                  </div>
                </div>
              </div>
              )}
              <div className="third-container">
                <div className="inner-container">
                  <div className="photo-container">
                    <img
                      src={photoselect}
                      alt="photo-select"
                      className="icon-size"
                    />
                    <h4 className="left-title">Photos</h4>
                    {/* <img src={port.PortPictures[0] ?? photoselect} alt="photo-select" className="icon-size" /> */}
                  </div>
                </div>
              </div>
            </>
          )}
          <Button
            type="primary"
            classes="w-40 sm-mt-2 mt-3"
            onClick={() => {
              if (editMode.active) {
                updateDoc(doc(db, "profile", profile[0].id), {
                  ...editableFields,
                });
                toast.success("Profile Updated Successfully");
                setEditMode({ active: false, field: null });
              }
              if (editPort.active) {
                updateDoc(doc(db, "Port", ports[0].id), {
                  ...editableFieldsPort,
                });
                toast.success("Port Updated Successfully");
                setEditPort({ active: false, field: null });
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
