import React from "react";
import { apple, google, facebook } from "../../assets/icons";
import { fireAuth } from "../../firebase";

const { instance: auth, signInWithPopup, GoogleAuthProvider , OAuthProvider , FacebookAuthProvider  } = fireAuth 

// const provider = new GoogleAuthProvider()
const appleProvider = new OAuthProvider('apple.com')
const facebookProvider = new FacebookAuthProvider()

const SocialButton = ({icon, label, onClick}) => <div className="social-login-item" onClick={onClick}>
    <img src={icon} />
    <p>Continue with {label}</p>
</div>

const TextArea = props => {
    return <div className={`social-login-container ${props.classes}`}>
        <SocialButton label={props.label} icon={props.icon} onClick={async () => {
            let provider;
            if(props.label === "Google") provider = new GoogleAuthProvider()
            else if(props.label === "Apple") provider = appleProvider
            else if(props.label === "Facebook") provider = facebookProvider
            const res = await signInWithPopup(auth, provider)
            props.onLogin(res)
        }}/>
    </div>
}

export default TextArea