import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { masterCard1, masterCard2, paypal, unpicked } from "../../../assets/icons";
import { NavBar, Card, TextInput, Button, Footer, Loader , CheckoutForm } from "../../../components";
import './payment.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { requireNumber, max, requireLetters, getTotalDays, getTotalAmount } from "../../../globalData";
import { store } from "../../../redux/store";
import { setParam, getAllParams } from "../../../urlParams"
import LocaleProvider from "antd/es/locale";
import { toast } from 'react-toastify'
import useGetDoc from "../../../hooks/useGetDoc";
import { fireAuth, fireDB } from "../../../firebase";

const { instance: auth, useAuthState } = fireAuth
const { db, doc, addDoc, collection } = fireDB


const stripePromise = loadStripe('pk_test_51M1prxAVjODwvEQhvDniiPh1Wa4BHuvIUmCSMGeQG0NvUhyY6dSOV81743Z0W9oYHITf9N33Erez5BhZKff4YEwV00hxvkCLri');

const Feature = props => <div className={`book-now-page-calculation-featue ${props.containerClasses}`}>
    {props.top && <h2>{props.top}</h2>}
    <div className="book-now-page-calculation-featue-price">
        <p className={props.firstClasses}>{props.first}</p>
        <h2 className={props.secondClasses}>{props.second}</h2>
    </div>
    <div className="separator w-all"></div>
</div>

export default function HomePage() {
    const navigate = useNavigate()
    const { port: portJSON } = getAllParams()
    const port = JSON.parse(portJSON)
    const [cardName, setCardName] = useState("")
    const [boat, setBoat] = useGetDoc("Boat", ["email", "==", "#email"])
    const [cardNumber, setCardNumber] = useState("")
    const [expiry, setExpiry] = useState("")
    const [cvv, setCvv] = useState("")
    const [user, userLoading, error] = useAuthState(auth)
    const [isLoading, setIsLoading] = useState(true)
    const [isPaypalSelected, setIsPaypalSelected] = useState(false)
    const [isCardSelected, setIsCardSelected] = useState(true)
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        if(boat && !userLoading) setIsLoading(false)
    }, [boat, userLoading])
    const stripePromise = loadStripe('pk_test_qblFNYngBkEdjEZ16jxxoWSM');
    const clientSecretDummy = 'pi_1JZ2ZvAVjODwvEQh1Z2ZvAVj'
    

    return (
        <div className="alpha-dashboard-main_container">
            <NavBar loaderValue={(data) => setIsLoading(data)} />
            <Card>
                <div className="payment-page-payment-container">
                    <Loader loading={isLoading}/>
                    <div className="payment-page-text">
                        <h2>Select Payment Method</h2>
                    </div>
                    <div className="payment-page-payment-method-container">
                        <div className="payment-page-choice">
                            <img src={isCardSelected ? masterCard1 : unpicked} onClick={() => {
                                setIsPaypalSelected(isCardSelected)
                                setIsCardSelected(!isCardSelected)}
                            }/>
                            <div className="payment-choice-contet">
                                <img src={masterCard2}/>
                                <h3>Master/VISA Card</h3>
                            </div>
                        </div>
                    </div>
                    {isCardSelected && <div className="payment-page-master-card-input-container">
                        <TextInput containerStyle="mt-2" inputStyle="w-50r" label="Card Name*" value={cardName} onChange={requireLetters(setCardName)}/>
                        <TextInput containerStyle="mt-2" inputStyle="w-50r" label="Card Number*" value={cardNumber?.split(" ")?.join("").split("")?.map((x, i) => (i+1)%4 == 0 ? `${x} ` : `${x}`)?.join('')?.trim()} onChange={txt => {
                            let n = 0
                            for(const ch of txt)
                                if([1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(x => `${x}`).includes(ch)) n += 1
                            if(n > 16) return
                            setCardNumber(txt)
                        }}/>
                        <div className="payment-page-cvv-expiry">
                            <TextInput containerStyle="mt-2 mb-2" label="Expiry Date*" value={expiry} onChange={txt => {
                                const erasing = expiry.split('/').join('').length > txt.split('/').join('').length
                                if(txt.length > 5) return
                                setExpiry(txt?.split("/")?.join("")?.split("")?.map((x, i) => {
                                    if(erasing && txt.length == 3) return `${x}`
                                    if(i !== 2) return `${x}`
                                    return `/${x}`
                                })?.join(''))
                            }}/>
                            <TextInput containerStyle="mt-2 mb-2" label="CVV*" value={cvv} onChange={max(3, setCvv)}/>
                        </div>
                    </div>}
                    <div className="book-page-book-now-button-container">
                        <Button disabled={!(cardName && cardNumber && expiry && cvv)} onClick={async () => {
                            if(boat.name === '' || boat.phone === ''){
                                toast.error("Please update your profile before booking")
                                return
                            }
                            setIsLoading(true)
                            const booking = JSON.parse(JSON.stringify({
                                boatId: boat.id,
                                createdAt: Date.now(),
                                portId: port.id,
                                bookingDetail: {
                                    boatUserName: boat.name,
                                    boatUserPhone: boat.phone,
                                    boatUserProfile: boat.profile,
                                    costPerNight: port.realRate,
                                    numberOfNights: getTotalDays(port.startDate, port.endDate),
                                    portId: port.id,
                                    portName: port.portName,
                                    portPicture: port.PortPictures[0],
                                    portUserName: port.name,
                                    portUserPhone: port.phone,
                                    portUserProfile: port.profile ? port.profile : null,
                                    time: {
                                        arrivalTime: new Date(port.startDate).getTime(),
                                        departureTime: new Date(port.endDate).getTime(),
                                    }
                                },
                                cardDetail: {
                                    cvc: cvv,
                                    expiry,
                                    number: cardNumber,
                                    type: "visa",
                                },
                                totalCost: getTotalAmount(port, port.startDate, port.endDate),
                                paymentDetail: {
                                    // id: "ch_3M7aTFJlv8F912l714gO6w5E",
                                    // receipt_url: "https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xTHVGM0JKbHY4RjkxMmw3KNLM_JsGMgb2lH-KwD06LBaUqYqjMBqILR-ze_pNOWXZPlzdwii4PuCm8aE0COk6L3kKalUs_NCzC5nT",
                                    // refundUrlEndPoint: "/v1/charges/ch_3M7aTFJlv8F912l714gO6w5E/refunds",
                                    portId: port.id,
                                }
                            }))

                            const newBooking = {
                                numberOfNights: getTotalDays(port.startDate, port.endDate),
                                portId: port.id,
                                portName: port.portName,
                                portPicture: port.PortPictures[0],
                                portUserName: port.name,
                                portUserPhone: port.phone,
                                portUserProfile: port.profile ?? null,
                                time: booking.bookingDetail.time,
                                totalCost: getTotalAmount(port, port.startDate, port.endDate)
                            }

                            console.log(newBooking, booking)

                            try {
                                const sanitizedBooking = Object.fromEntries(Object.entries(booking).map(([k, v]) => [k, v === undefined ? null : v, console.log(v === undefined ? null : v)]))
                                const sanitizedNewBooking = Object.fromEntries(Object.entries(newBooking).map(([k, v]) => [k, v === undefined ? null : v, console.log(v === undefined ? null : v)]))

                                const res = await addDoc(collection(db, "Bookings"), sanitizedBooking)
                                await addDoc(collection(db, "NewBookings"), sanitizedNewBooking)
                                console.log(res)                                
                            } catch (e) {
                                console.error(e)
                            }

                            setIsLoading(false)
                            navigate("/bookingDone")
                        }} type="primary" classes="w-50r">Pay Now</Button>
                    </div>
                </div>
            </Card>
            {/* <Elements stripe={stripePromise} options={{
                clientSecretDummy
            }}>
                <CheckoutForm />
            </Elements> */}
            <Footer/>
        </div>
    );
}
