import React, { useState } from "react";
import { DateRange } from "react-date-range";
import { userImage } from "../../../assets/icons";
import { addDays, format, isWeekend } from "date-fns";
import useGetDocs from "../../../hooks/useGetDocs";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./bookingDetail.css";
import { Loader } from "../../../components";

export default function BookingDetail({ portID }) {
  const [results, setResults, loading] = useGetDocs("Bookings", [
    "portId",
    "==",
    portID,
  ]);

  const changeDateFromat = (date) => {
    const newDate = new Date(date);
    const formattedDate = newDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    const finalDate = formattedDate.replace(/ /g, "-");
    return finalDate;
  };

  function customDayContent(day) {
    return (
      <div
        style={{
          height: "20px",
          width: "20px",
          borderRadius: "100%",
          background: "orange",
          position: "absolute",
          backgroundColor: "pink",
          top: 10,
          left: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ position: "relative" }}>
          <img
            src={userImage}
            alt="user"
            style={{ position: "absolute", top: 0, left: 0 }}
          />
          <span>{format(day, "d")}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bookingDetail-content">
      <Loader loading={loading} />
      <div className="bookingDetail-header">
        <h1>Booking Details</h1>
      </div>
      <div className="bookingDetail-body">
        <div className="bookingDetail-body-left">
          <h3>Bookings</h3>
          {results?.map((booking, index) => (
            <div className="bookingDetail-card">
              <div className="bookingDetail-card-header">
                <div className="bookingDetail-card-header-left">
                  <img src={userImage} alt="user" />
                  <div>
                    <h2 className="bookingDetail-userName">
                      {booking.bookingDetail.boatUserName}
                    </h2>
                    <p className="bookingDetail-portName">
                      {booking.bookingDetail.portName}
                    </p>
                  </div>
                </div>
                <div
                  className={`bookingDetail-card-header-right ${
                    index % 2 === 0 ? "" : "light-blue"
                  }`}
                >
                  <div className="bookingDetail-card-header-right-sub">
                    <div className="bookingDetail-card-header-right-item">
                      <h2 className="bookingDetail-card-header-right-item-text">
                        From:
                      </h2>
                      <h2 className="bookingDetail-card-header-right-item-text">
                        To:
                      </h2>
                    </div>
                    <div className="bookingDetail-card-header-right-item">
                      <p>
                        {changeDateFromat(
                          booking.bookingDetail.time.arrivalTime
                        )}
                      </p>
                      <p>
                        {changeDateFromat(
                          booking.bookingDetail.time.departureTime
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="bookingDetail-body-right">
          <DateRange
            ranges={results?.map((booking) => ({
              startDate: new Date(booking.bookingDetail.time.arrivalTime),
              endDate: new Date(booking.bookingDetail.time.departureTime),
            }))}
            rangeColors={results?.map((booking, index) =>
              index % 2 === 0 ? "#ffd8ab" : "#AFC6EB"
            )}
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            className="bookingDetail-calendar"
            style={{ width: "400px" }}
            customDayContent={customDayContent}
          />
        </div>
      </div>
    </div>
  );
}
