import React from "react";
import { profileImage, akarIcon } from "../../assets/icons";

const EarningCard = ({ username, portName, price }) => {
  return (
    <div style={cardStyle}>
      <div style={avatarStyle}>
        <div style={profileHeader}>
          <img src={profileImage} alt="profile" style={profileImageStyle} />
          <div>
            <h1 style={userTitle}>{username}</h1>
            <h1 style={portTitle}>{portName}</h1>
          </div>
        </div>
        <div style={profileHeader}>
          <img src={akarIcon} alt="akar" style={akarIconStyle} />
          <h1 style={amountTitle}>{price}</h1>
        </div>
      </div>
    </div>
  );
};

const cardStyle = {
  backgroundColor: "#fff",
  borderRadius: "16px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  margin: "20px",
  padding: "10px",
  width: "400px",
};

const avatarStyle = {
  display: "flex",
  justifyContent: "space-between",
};
const profileHeader = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const userTitle = {
  fontSize: "16px",
  //   textAlign: "center",
  fontFamily: "Poppins",
  color: "#5F5F5F",
  textAlign: "center",
};
const portTitle = {
  fontSize: "12px",
  color: "#929292",
  fontWeight: "400",
  fontFamily: "Poppins",
  lineHeight: "18px",
  // textAlign : 'center',
  marginTop: "5px",
};
const profileImageStyle = {
  width: "50px",
  height: "50px",
  borderRadius: "20%",
  marginRight: "20px",
};

const akarIconStyle = {
  width: "14px",
  height: "14px",
};

const amountTitle = {
    fontSize: "16px",
    color: "#5BB269",
    fontWeight: "700",
    fontFamily: "Poppins",
    lineHeight: "20px",
    textAlign: "center",
    marginLeft: "10px",
};

export default EarningCard;
