import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import * as Viewers from "../pages/viewers";
import * as Admin from "../pages/admin";
import { store } from "../redux/store";
import PrivateRoute from "./protected-route";
import { useSelector } from "react-redux";

// import * as Admin from "../pages/admin";

const Navigation = () => {
  const user = useSelector((data) => data.userData.userData);



  return (
    <Routes>
      <Route path="/" element={<Viewers.HomePage />} />
      <Route path="/account-type" element={<Viewers.AccountType />} />
      <Route path="/locations" element={<Viewers.Locations />} />
      <Route path="/about-us" element={<Viewers.AboutUs />} />
      <Route path="/contact-us" element={<Viewers.ContactUs />} />
      <Route path="/register" element={<Viewers.Register />} />
      <Route path="/product-details" element={<Viewers.ProductDetails />} />
      <Route path="/login" element={<Viewers.Login />} />
      <Route path="/registerBoat" element={<Viewers.RegisterBoat />} />
      <Route path="/bookNow" element={<Viewers.BookNow />} />
      <Route path="/payment" element={<Viewers.Payment />} />
      <Route path="/bookingDone" element={<Viewers.BookingDone />} />
      <Route path="/myBookings" element={<Viewers.MyBookings />} />
      <Route path="/updateProfile" element={<Viewers.UpdateProfile />} />
      <Route path="/updateBoats" element={<Viewers.UpdateBoat />} />
      <Route path="/changePassword" element={<Viewers.ChangePassword />} />
      <Route path="/forgetPassword" element={<Viewers.ForgetPassword />} />
      <Route path="/otpScreen" element={<Viewers.OtpScreen />} />
      <Route path="/resetPassword" element={<Viewers.ResetPassword />} />
      <Route path="/*" element={<Viewers.NotFound />} />
      {/* admin routes */}
      <Route path="/AddPort" element={<Admin.AddPort />} />
      <Route path="/RegisterPort" element={<Admin.RegisterPort />} />
      <Route path="/Dashboard" element={<Admin.Dashboard />} />

      {/* for all */}
    </Routes>
  );
};

export default Navigation;
