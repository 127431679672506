import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  logo,
  location,
  calendar,
  play,
  apple,
  clock,
  homeBoats,
  searchHome,
  book,
  enjoy,
  homeShips,
  arrow,
  portSelection,
} from "../../../assets/icons";
import {
  NavBar,
  ContactUs,
  AppDownload,
  SearchCard,
  ResultCard,
  Footer,
  Button,
} from "../../../components";
import {
  FinancingStepFourAdmin,
  FinancingStepOneAdmin,
  FinancingStepThreeAdmin,
  FinancingStepTwoAdmin,
  Loader,
  SideBar,
  SubmitModel,
  TopBar,
} from "../../../components";
import "./homePage.css";
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";
import useGetDoc from "../../../hooks/useGetDoc";
import { fireDB } from "../../../firebase";

const { db, collection, doc, getDocs, getDoc, query, where, onSnapshot } =
  fireDB;

const OverviewPoint = ({ number, title, desc }) => (
  <div className="overview-point">
    <div className="overview-point-number">{number}</div>
    <div className="overview-point-title">{title}</div>
    <div className="overview-point-desc">{desc}</div>
  </div>
);

export default function HomePage() {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [coordinates, setCoordinates] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [result, setResult] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const finalQ = query(
      collection(db, "Port"),
      where("ratePerNight", "!=", "0")
    );
    onSnapshot(finalQ, (docs) => {
      const docArr = [];
      docs.forEach((doc) => docArr.push(doc));
      const nextDocs = docArr.map((doc) => ({ id: doc.id, ...doc.data() }));
      const doc = nextDocs[0];
      console.log(nextDocs);
      setResult({
        ...doc,
        name: doc?.portName,
        desc:
          (doc.discription ?? doc.description)?.length > 100
            ? (doc.discription ?? doc.description)?.slice(0, 100)
            : doc.discription ?? doc.description,
        coordinates: [doc.lat, doc.lng],
        rate: `$${doc.ratePerNight}/night`,
      });
    });
  }, []);

  return (
    <div className="alpha-dashboard-main_container">
      <NavBar loaderValue={(data) => setIsLoading(data)} />
      <div className="home-container">
        <div className="home-background">
          <div className="home-text">
            <h1 className="home-title">Find your perfect Marina</h1>
            <h4 className="home-subtitle">
              Securely reserve you berthing spot with ease, ensuring a smooth
              and convenient experience at NautalPay
            </h4>
          </div>
        </div>
      </div>
      <SearchCard
        searchButton={true}
        containerClasses="home-margin"
        location={location}
        coordinates={coordinates}
        date={date}
        time={time}
        setLocation={setLocation}
        setCoordinates={setCoordinates}
        setDate={setDate}
        setTime={setTime}
      />
      <div className="banner-container">
        <div className="banner-background">
          <div className="banner">
            <div className="banner-text">
              <h1 className="banner-title">Choose your Marina</h1>
              <h4 className="banner-subtitle">
                Find your marina on the map and reserve it
              </h4>
            </div>
            <ResultCard
              coordinateFontClasses="home-result-font"
              cardClasses="w-unset"
              name={result?.name}
              image={result?.PortPictures?.[0]}
              desc={result?.desc}
              coordinates={result?.coordinates}
              rate={result?.rate}
              moreDetails={true}
            />
          </div>
        </div>
      </div>
      <div className="info-container">
        <h2>How it works</h2>
        <div className="info-icon-container">
          <div className="info-icon sm-only-flex">
            <div className="info-icon-top">
              <img src={searchHome} />
              <img src={book} />
            </div>
            <div className="info-icon-bottom">
              <img src={enjoy} />
            </div>
          </div>
          <div className="sm-none info-icon">
            <img src={searchHome} />
            <img src={book} />
            <img src={enjoy} />
          </div>
        </div>
      </div>
      <div className="overview-container">
        <div className="overview">
          <div className="overview-text-points">
            <OverviewPoint
              number="01"
              title="Select your desired Marina"
              desc="Get an easy overview of the various marinas in the Gulf and beyond on our simple to use map"
            />
            <OverviewPoint
              number="02"
              title="Reserve your berth"
              desc="Reserve your berth or mooring in advance to ensure perfect spot is waiting for you"
            />
            <OverviewPoint
              number="03"
              title="Pay and Own your location"
              desc="Select where you are staying and how long and pay directly to the marina using Nautical Pay - No need for cash"
            />
          </div>
          <div className="overview-image">
            <img src={homeShips} />
          </div>
        </div>
      </div>
      <div className="about-container">
        <div className="about-icon">
          <img src={logo} />
          <h2>About NauticalPay</h2>
        </div>
        <div className="about-text">
          <p>
            Here at Nautical Pay our mission is to make it easier for everyone
            to experience the world on water. By investing in technology that
            helps take the friction out of selecting, navigating and docking at
            ports throughout the region.
            <br />
            <br />
            Nautical Pay seamlessly connects thousands of sailing enthusiasts
            with memorable experiences every day.
          </p>
          <div
            className="read-more-about"
            onClick={() => navigate("/about-us")}
          >
            <p>Read More</p>
            <img src={arrow} />
          </div>
        </div>
      </div>
      <div className="overview-container">
        <div className="overview">
          <div className="overview-image">
            <img src={portSelection} className="port-selection" />
          </div>
          <div className="registar-text-points">
            <div className="registar-text-title">Register You Port</div>
            <div className="registar-text-desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.{" "}
            </div>
            <Button 
              type="primary" 
              classes="btn" 
              onClick={() => navigate("/account-type")}
            >
              Register
            </Button>
          </div>
        </div>
      </div>
      <ContactUs />
      <AppDownload />
      <Footer />
    </div>
  );
}
