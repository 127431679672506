import React, { useState , useRef } from "react";
import { Button } from "../../../components";
import { fireAuth, fireDB, fireStorage } from "../../../firebase";
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import "./customerSupport.css";


const { instance: auth, useAuthState } = fireAuth;
const { db, collection, addDoc, query, where, getDocs } = fireDB;

export default function CustomerSupport() {
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async (e) => {
    if (!subject || !message) {
      toast.error("Please fill all the fields");
      return;
    }
    e.preventDefault();

    const serviceID = 'service_juqwxgk';
    const templateID = 'template_33av6df';
    const userID = 'YbXIMQiF294RCHU0u';

    const templateParams = {
      subject,
      message,
      email: user.email
    }

    emailjs.send(serviceID, templateID, templateParams, userID).then((res) => {
      toast.success("Email sent successfully");
      form.current.reset();
      setSubject("");
      setMessage("");
    }
    ).catch((err) => {
      toast.error("Email not sent. Please try again");
    });
  }

  return (
    <div className="customer-contant">
      <div className="customer-header">
        <h1>Customer Support</h1>
      </div>
      <div className="main-customer-conatiner">
        <div className="main-customer-support-left">
          <span className="contact-title">Lorem Ipsum dolor set amet</span>
          <span className="customer-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ipsum
            urna, rhoncus et malesuada eget, finibus eget ex. Nam ipsum urna,
            rhoncus et malesuada eget, finibus eget ex
          </span>
          <h4 className="customer-email">company@info.pk.com</h4>
          <h4 className="customer-phone">+92-305-4597792</h4>
          <h4 className="customer-support">contact Support</h4>
        </div>
        <div className="main-customer-support-right">
          <div className="customer-form">
            <span className="contact-title">Get in touch</span>
            <form ref={form} onSubmit={sendEmail}>
              <h4 className="customer-form-title">
                Get in touch with us by filling the form below
              </h4>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Subject"
                  value={subject || ""}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group">
                <textarea
                  placeholder="Message"
                  value={message || ""}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="button-container">
                <Button classes="w-72p sm-mt-2 sm-mb-2" type="primary" onClick={sendEmail}>
                  Save
                </Button>
              </div>
              <p className="agreement-statement">
                By contacting us, you agree to our Terms of Service and Privacy
                Policy.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
