import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, TextInput, TwoScreenLayout } from "../../../components";
import "./aboutPort.css";
import { handleUpload } from "../../../components/imageInput/imageInput";
import { camera } from "../../../assets/icons";
import SyncLoader from "react-spinners/SyncLoader";

export default function AboutPort() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileImage, setProfileImage] = useState("");

  return (
    <TwoScreenLayout height="115vh" containerHeight="115vh">
      <h1>Tell us About Port</h1>
      <input
        id="input"
        type="file"
        style={{ display: "none" }}
        onChange={handleUpload((url) =>
          setProfileImage(url)
        )}
      />
      <img
        className="icon"
        src={
          profileImage
            ? profileImage
            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRypgMMZNtrG9MPif00Vr2YHisnxeZjZrUrWA&usqp=CAU"
        }
        alt="icon"
      />
      <img
        className="camera-img"
        src={camera}
        onClick={() => document.getElementById("input").click()}
      />
      <TextInput
        containerStyle="mb-2 register-input-container"
        inputStyle="register-input"
        label="Full Name"
        value={fullName || ""}
        onChange={setFullName}
      />
      <TextInput
        containerStyle="mb-2 register-input-container"
        inputStyle="register-input"
        label="Phone Number"
        value={phoneNumber || ""}
        onChange={setPhoneNumber}
      />
      <Button
        type="primary"
        classes="w-72p sm-mt-2"
        onClick={() => {
          if (!(fullName && phoneNumber)) {
            toast.error("Please fill all fields");
            return;
          };
          navigate("/registerPort" ,  { state: { fullName, phoneNumber, profileImage } });
        }}
      >
        {isLoading ? <SyncLoader color="white" /> : "Continue"}
      </Button>
    </TwoScreenLayout>
  );
}
