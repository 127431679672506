import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { fireAuth, fireDB, fireStorage } from "../../../firebase";
import {
  Button,
  TextInput,
  TwoScreenLayout,
  TextArea,
} from "../../../components";
import { uploadIcon } from "../../../assets/icons";
import "./registerPort.css";
import SyncLoader from "react-spinners/SyncLoader";

const { instance: auth, useAuthState } = fireAuth;
const { db, collection, addDoc, query, where, getDocs } = fireDB;
const { storage, ref, uploadBytes, getDownloadURL } = fireStorage;

export default function RegisterPort() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [portName, setPortName] = useState("");
  const [location, setLocation] = useState("");
  const [coordinates, setCoordinates] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [inputFacility, setInputFacility] = useState("");
  const [ratesPerNight, setRatesPerNight] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const locationState = useLocation();
  const { fullName, phoneNumber, profileImage } = locationState.state || {};

  const handleInputChange = (event) => {
    setInputFacility(event.target.value);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && inputFacility) {
      event.preventDefault();
      if (!facilities.includes(inputFacility.trim())) {
        setFacilities([...facilities, inputFacility.trim()]);
        setInputFacility("");
      } else {
        console.log("Facility already added");
      }
    }
  };

  const maxNumber = 69;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);
  
  const handleSuccess = async (position) => {
    const { latitude, longitude } = position.coords;
    setCoordinates({ latitude, longitude });
    await fetchAddress(latitude, longitude);
  };
  const handleError = (error) => {
    setError(error.message);
  };
  const fetchAddress = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      if (!response.ok) throw new Error("Failed to fetch address");
      const data = await response.json();
      setLocation(data.display_name);
      setCoordinates(`${lat}, ${lon}`);
    } catch (err) {
      setError(err.message);
    }
  };
  const handleUpload = async (imageList) => {
    setIsLoading(true);
    const uploadPromises = imageList.map(async (image) => {
      const file = image.file;
      const storageRef = ref(
        storage,
        `Port/${Math.random() * 10000 + 10000}-${file.name}`
      );

      const snapshot = await uploadBytes(storageRef, file);
      return await getDownloadURL(snapshot.ref);
    });

    try {
      const imageUrls = await Promise.all(uploadPromises);
      setImages((prevImages) => [...prevImages, ...imageUrls]);
    } catch (error) {
      toast.error("Error uploading images");
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    const newImages = imageList.filter((image) => image.data_url);
    handleUpload(newImages);
  };
  const navigate = useNavigate();

  const handleRegisterPort = async () => {
    if (
      !(
        portName &&
        location &&
        coordinates &&
        ratesPerNight &&
        description &&
        facilities.length > 0
      )
    ) {
      toast.error("Please fill all fields");
      return;
    }
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      const portRef = await addDoc(collection(db, "Port"), {
        Location: location,
        PortPictures: images,
        discription: description,
        lat : coordinates[0],
        lng : coordinates[1], 
        phone : phoneNumber,
        portName : portName,
        portfacilities : facilities,
        profile : profileImage,
        ratePerNight : ratesPerNight,
        name: fullName,
        userId: user.uid,
        email: user.email,
      });
      console.log("Document written with ID: ", portRef.id);
      setIsLoading(false);
      toast.success("Port added successfully");
      navigate("/dashboard", { replace: true });

    } catch (e) {
      console.error("Error adding document: ", e);
      toast.error("Error adding document");
      setIsLoading(false);
    }
  };

  return (
    <TwoScreenLayout height="150vh" containerHeight="185vh">
      <div className="mb-2">
        <div className="text-container">
          <h1>Tell us About Port</h1>
        </div>
        <div className="mb-2"></div>
        <TextInput
          containerStyle="mb-2"
          label="Enter Port Name"
          value={portName}
          onChange={setPortName}
        />
        <TextInput
          containerStyle="mb-2"
          label="Enter Location"
          value={location}
          onChange={setLocation}
        />
        <TextInput
          containerStyle="mb-2"
          label="Enter Coordinates"
          value={coordinates}
          onChange={setCoordinates}
        />
        <TextInput
          containerStyle="mb-2"
          label="Enter Rates Per Night"
          value={ratesPerNight}
          onChange={setRatesPerNight}
        />
        <div className="custom-text-input">
          <label>Enter Facilities</label>
          <input
            type="text"
            value={inputFacility}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
          />
          <div className="facilities">
            {facilities.map((facility, index) => (
              <div key={index} className="facility">
                {facility}
              </div>
            ))}
          </div>
          <label className="input-error">*Press Enter to add facility</label>
        </div>
        <TextArea
          label="Enter Description"
          containerStyle="mb-2 "
          labelStyle="mb-half"
          value={description}
          onChange={setDescription}
        />
        <div className="image-container">
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({ imageList, onImageUpload, onImageRemove }) => (
              <div className="upload-image-warpper">
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image} alt="" className="image" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="remove-image"
                        onClick={() => {
                          onImageRemove(index);
                          setImages(images.filter((img, i) => i !== index));
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <img
                  src={uploadIcon}
                  alt="upload"
                  onClick={onImageUpload}
                  className="image"
                />
              </div>
            )}
          </ImageUploading>
        </div>
        <Button
          type="primary"
          classes="w-40 sm-mt-2 mt-3"
          onClick={() => {
            handleRegisterPort();
            // navigate("/dashboard");
          }}
        >
          {isLoading ? <SyncLoader color="white" /> : "Continue"}
        </Button>
      </div>
    </TwoScreenLayout>
  );
}
