import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from "react-router-dom";
import { fireAuth, fireDB } from '../../../firebase'
import useProtection from '../../../hooks/useProtection'
import useGetDoc from '../../../hooks/useGetDoc'
import { uncle, camera } from "../../../assets/icons";
import { NavBar, TextInput, Button, SearchCard, ResultCard, Footer, UpdateScreen } from "../../../components";
import { handleUpload } from "../../../components/imageInput/imageInput";
import { Loader} from "../../../components";
import './updateProfile.css'
import { authErrors } from "../../../globalData";
import SyncLoader from "react-spinners/SyncLoader";

const { instance: auth, useAuthState } = fireAuth
const { db, collection, doc, query, where, setDoc, getDocs } = fireDB

export default function HomePage() {
  const navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)
  const [boat, setBoat] = useGetDoc("Boat", ["email", '==', "#email"])
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  useProtection()

  useEffect(() => {
    if(boat) {
      setProfileImage(boat.profileImage)
      setEmail(boat.email)
      setName(boat.name)
      setPhoneNumber(boat.phone)
      setIsLoading(false)
    }
  }, [boat])

  // useEffect(() => {
  //   const f = async () => {
  //     if(user) {
  //       const userBoatQ = await getDocs(query(collection(db, "Boat"), where("email", '==', user.email)))
  //       let userBoat;
  //       userBoatQ.forEach(x => userBoat = {id: x.id, ...x.data()})
  //       setProfileImage(userBoat.profileImage)
  //       setEmail(userBoat.email)
  //       setName(userBoat.name)
  //       setPhoneNumber(userBoat.phone)
  //       setBoat(userBoat)
  //       setIsLoading(false)
  //     }
  //   }
  //   f()
  // }, [user])

  return <UpdateScreen default="Profile">
        <input id="input" type="file" style={{ display: "none" }} onChange={handleUpload(url => setProfileImage(url))}/>
        <Loader loading={isLoading}/>
        <img className="uncle-img" src={profileImage ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRypgMMZNtrG9MPif00Vr2YHisnxeZjZrUrWA&usqp=CAU"} />
        <img className="camera-img" src={camera} onClick={() => document.getElementById("input").click()} />
        <TextInput label="Name*"  containerStyle="mb-2" inputStyle="w-42r" value={name} onChange={setName}/>
        <TextInput label="Email" disabled containerStyle="mb-2" inputStyle="w-42r" value={email} onChange={setEmail}/>
        <TextInput label="Phone Number" containerStyle="mb-2" inputStyle="w-42r" value={phoneNumber} onChange={setPhoneNumber}/>
        <Button type="primary" classes={`mt-6 ${name && email ? "" : "opacity-disabled"}`} onClick={async () => {
          if(!(name && email)) return
          setIsButtonLoading(true)
          try {
              await setBoat({
                name,
                phone: phoneNumber,
                ...(profileImage ? { profileImage } : {})
              })
              toast.success("Profile Updated Successfully")
            } catch (e) {
                toast.error(authErrors[e.code?.replace("auth/", "")] ?? "An error has occured")
            }
            setIsButtonLoading(false)
        }}>{isButtonLoading ? <SyncLoader color="white"/> : "Update Profile"}</Button>
        <Button classes="mt-2 w-42r" onClick={() => navigate("/changePassword")}>Change Password</Button>
    </UpdateScreen>
}
