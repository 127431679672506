import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { copyRightText, logoBig, facebook, instagram, twitter, youtube, linkedin } from '../../assets/icons'
import './footer.css'
import { activeTab } from '../../redux/Slices/activeTabSlice'
import { store } from '../../redux/store'
import { useSnackbar } from 'react-simple-snackbar'
import { snakbarOptions } from '../../globalData'

export default function Footer() {
    return <div className="footer">
    <div className="separator" style={{ width: "100%" }}></div>
    <div className="footer-content">
      <div className="footer-content-container">
        <div className="footer-logo">
          <img src={logoBig}/>
        </div>
        <div className="footer-links">
          <h2>Useful Links</h2>
          <p>My Account</p>
          <p>Get the App</p>
          <p>Help Center</p>
          <p>Terms & Conditions</p>
        </div>
        <div className="footer-links">
          <h2>Locations</h2>
          <p>United Arab Emirates</p>
        </div>
        <div className="footer-links">
          <h2>Social Links</h2>
          <div className="footer-social-icons">
            <img src={facebook}/>
            <img src={instagram}/>
            <img src={linkedin}/>
            <img src={youtube}/>
            <img src={twitter}/>
          </div>
        </div>
      </div>
    </div>
    <div className="separator" style={{ width: "100%" }}></div>
    <div className="footer-copyright">
      <p>Copyright @2023 all rights reserved.</p>
    </div>
  </div>
}

