import React from 'react';
import loader from '../../assets/icons/loader.json';
import './loader.css'
import SyncLoader from 'react-spinners/SyncLoader';

const Loader = props => {
    return (
        props.loading ?
            <div className="loader" style={{ position: "fixed" }}>
                <SyncLoader color="#376FCC" className='alpha_loader_style' animationData={loader} />
            </div>
            :
            <div />
    );
};

export default Loader

