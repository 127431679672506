import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { useLocation, useNavigate } from "react-router-dom";
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube } from "../../../assets/icons";
import { NavBar, ResultCard, Button, Footer } from "../../../components";
import { setParam, getAllParams } from "../../../urlParams"
import './bookNow.css'
import { TimePicker, DatePicker, Space } from 'antd';
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { getTotalAmount, getTotalDays } from "../../../globalData";
import { store } from "../../../redux/store";
import LocaleProvider from "antd/es/locale";
import useDisabledDate from "../../../hooks/useDisabledDate";

const { RangePicker } = DatePicker;

const Feature = props => <div className={`book-now-page-calculation-featue ${props.containerClasses}`}>
    {props.top && <h2>{props.top}</h2>}
    <div className="book-now-page-calculation-featue-price">
        <p className={props.firstClasses}>{props.first}</p>
        <h2 className={props.secondClasses}>{props.second}</h2>
    </div>
    {props.error && <p className="book-now-page-calculation-featue-error-message">{props.error}</p>}
    <div className="separator w-all"></div>
</div>

const rangeDays = (startDate, endDate) => {
    var dateArray = [];
    var currentDate = new Date(startDate);
    endDate = new Date(endDate);
  
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dateArray;
  }

export default function HomePage() {
    const navigate = useNavigate()
    const { port: postJSON } = getAllParams()
    const doc = JSON.parse(postJSON)
    const [isLoading, setIsLoading] = useState(false)
    const disabledDate = useDisabledDate(doc.id)
    const [startDate, setStartDate] = useState(doc.startDate ?? new Date().toISOString())
    const [endDate, setEndDate] = useState(doc.endDate ?? new Date(Date.now() + 24*60*60*1000).toISOString())

    console.log(doc)

    return (
        <div className="alpha-dashboard-main_container">
            <NavBar loaderValue={(data) => setIsLoading(data)} />
            <div className="book-now-page-book-now-container">
                <div className="book-now-page-book-now-content">
                    <ResultCard containerClasses="mt-2" cardClasses="w-unset" contentClasses="mr-2" priceClasses="justify-content-center border-price" name={doc.name} desc={doc.desc} coordinates={doc.coordinates} image={doc.PortPictures?.[0]} rate={<div className="rate-container">
                        <h2>${doc.realRate.slice(0, 4)}/</h2>
                        <p>night</p>
                    </div>} moreDetails={false}>
                        <div className="book-now-page-calculation-section">
                            <Feature containerClasses="mt-6" error={
                                rangeDays(startDate, endDate).some(x => disabledDate(x.getTime())) ? 
                                "Some of these dates have already been booked" : 
                                null
                            } firstClasses={"h-unset"} top="From" first={<RangePicker
                                className="home-search-field-input-dates"
                                inputStyle={{
                                    width: "200px",
                                }}
                                disabledDate={x => disabledDate(new Date(x).getTime())}
                                defaultValue={[dayjs(startDate.split("T")[0], 'YYYY-MM-DD'), dayjs(endDate.split("T")[0], 'YYYY-MM-DD')]}
                                onChange={([startDate, endDate]) => {
                                    setStartDate(new Date(startDate).toISOString())
                                    setEndDate(new Date(endDate).toISOString())
                                }}
                                suffixIcon={""}
                                cellRender={(current, info) => {
                                    if (info.type !== 'date') return info.originNode;
                                    const style = {}
                                    if (current.date() === 1) {
                                        style.border = '1px solid #1677ff';
                                        style.borderRadius = '50%';
                                    }
                                    return (
                                    <div className="ant-picker-cell-inner" style={style}>
                                        {current.date()}
                                    </div>
                                    );
                                }}
                            />} second={`${getTotalDays(startDate, endDate)} Nights`} />
                            <Feature containerClasses="mt-1" first="Price" firstClasses="book-now-text-big color-black" second={`${doc.realRate.slice(0, 4)}/night`} secondClasses="book-now-text-big color-black" />
                            <Feature containerClasses="mt-1" first="Sub Total" firstClasses="book-now-text-big color-grey" second={`$${parseInt(doc.realRate.slice(0, 4))}${startDate ? `*${Math.round((new Date(endDate).getTime() - new Date(startDate).getTime())/(24*60*60*1000))}` : ""}`} secondClasses="book-now-text-big color-grey" />
                            <Feature containerClasses="mt-1" first="Vat" firstClasses="book-now-text-big color-grey" second="$26" secondClasses="book-now-text-big color-grey" />
                            <Feature containerClasses="mt-1" first="Total" firstClasses="book-now-text-big color-black" second={`$${getTotalAmount(doc, startDate, endDate)}`} secondClasses="book-now-text-big color-black" />
                            <div className="book-page-book-now-button-container">
                                <Button disabled={rangeDays(startDate, endDate).some(x => disabledDate(x.getTime()))} onClick={() => navigate(`/payment?${setParam({
                                    port: JSON.stringify({...doc, startDate, endDate}),
                                })}`)} type="primary" classes="w-50p">Book Now</Button>
                            </div>
                        </div>
                    </ResultCard>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
