import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fireAuth, fireDB } from '../firebase'
import useGetDoc from "./useGetDoc";
const { instance: auth, useAuthState } = fireAuth
const { db, collection, doc, query, where, setDoc, getDocs, onSnapshot } = fireDB

const useGetDocs = (colName, q, f=()=>{}) => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const [boat, _] = useGetDoc("Boat", ["email", "==", "#email"])
    const [port, __] = useGetDoc("Port", ["email", "==", "#email"])
    const [data, setData] = useState(null)
    const [thisLoading, setThisLoading] = useState(true)

    useEffect(() => {
        const f = async () => {
          if(user && boat) {
            if(q[2]?.startsWith("#")) q[2] = user[q[2].replace("#", "")]
            if(q[2]?.startsWith("@")) q[2] = boat[q[2].replace("@", "")]
            const finalQ = query(collection(db, colName), where(...q))
            onSnapshot(finalQ, docs => {
                const docArr = []
                docs.forEach(doc => docArr.push(doc))
                const nextDocs = docArr.map(doc => ({id: doc?.id, ...doc.data()}))
                setData(nextDocs)
            })
            // setData(_.map(doc => ({id: doc.id, ...doc.data()})))
            setThisLoading(false)
          }
          else if(user && port) {
            if(q[2]?.startsWith("#")) q[2] = user[q[2].replace("#", "")]
            if(q[2]?.startsWith("@")) q[2] = port[q[2].replace("@", "")]
            const finalQ = query(collection(db, colName), where(...q))
            onSnapshot(finalQ, docs => {
                const docArr = []
                docs.forEach(doc => docArr.push(doc))
                const nextDocs = docArr.map(doc => ({id: doc.id, ...doc.data()}))
                setData(nextDocs)
            })
            // setData(_.map(doc => ({id: doc.id, ...doc.data()})))
            setThisLoading(false)
          }
          // setThisLoading(false)
          setTimeout(() => setThisLoading(false), 5000)
        }
        f()
    }, [user, boat, port])

    const frontSetData = async givenData => {
      const docRef = doc(db, colName, data?.id)
      setData(data.map(item => item.id == givenData.id ? {...item, ...givenData} : item))
      await setDoc(docRef, givenData, { merge: true })
    }

    return [data, frontSetData, thisLoading]
}

export default useGetDocs;