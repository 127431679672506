import React, { useState, useRef } from 'react'
import { eye, eyeCross } from "../../assets/icons";
import './passwordInput.css'

const PasswordInput = ({label, inputStyle, labelStyle, containerStyle, value, onChange}) => {
    const ref = useRef(<input/>)
    const [show, setShow] = useState(false)

    return <div className={`text-input ${containerStyle}`} onClick={() => ref.current.focus()}>
        <label className={labelStyle}>{label}</label>
        <div className='password-input-container'>
            <input ref={ref} className={inputStyle} type={show ? 'text' : 'password'} value={value} onChange={ev => onChange(ev.target.value)}/>
            <img src={show ? eye : eyeCross} onClick={() => setShow(!show)}/>
        </div>
    </div>
}

export default PasswordInput
