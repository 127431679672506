import React from 'react'
import './textInput.css'

const TextInput = ({label, error, inputStyle, labelStyle, disabled, errorStyle, containerStyle, value, onChange}) => {
    return <div className={`text-input ${containerStyle}`}>
        <label className={labelStyle}>{label}</label>
        <input className={inputStyle} type="text" readOnly={disabled} value={value} onChange={ev => (disabled ? () => {} : (onChange ?? (() => {})))(ev.target.value)}/>
        <label className={`${errorStyle} input-error`}>{error}</label>
    </div>
}

export default TextInput