import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube } from "../../../assets/icons";
import { NavBar, AppDownload, ContactUs, Footer } from "../../../components";
import './aboutUs.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";

export default function HomePage() {
    const [isLoading, setIsLoading] = useState(false)
    const [selections, setSelections] = useState([
        {
            heading: "About Nautical Pay",
            text: <div>
                At Nuatical Pay our goal is to embrace a community of like minded sailing enthusiasts, helping sailors throughtout the Gulf to explore and enjoy new horizons, in as simple and convenient a wayas possible, allowing you to spend more time enjoying the water with your friend and family.
                <br/>
                <br/>
                With over 50 marinas spread across the region we want to enable our community to set sail and experience new adventures and see what this wonderful region has to offer. Estabilshed in the UAE we are aiming to become the regions leading marina booking site, connecting our clients with the best marina services available. 
                <br/>
                <br/>
                Wherever you want to go and whatever you want to do, Nautical Pay makes it easy and supports you with 24/7 customer support.
            </div>
        },
        {
            heading: "Legal",
            text: <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
            molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
            numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
            optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
            obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
            nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
            tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
            quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos 
            sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
            recusandae alias error harum maxime adipisci amet laborum. Perspiciatis 
            minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit 
            quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur 
            fugiat, temporibus enim commodi iusto libero magni deleniti quod quam 
            consequuntur! Commodi minima excepturi repudiandae velit hic maxime
            doloremque. Quaerat provident commodi consectetur veniam similique ad 
            earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo 
            fugiat, dolorum eligendi quam cupiditate excepturi mollitia maiores labore 
            suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium
            modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam 
            totam ratione voluptas quod exercitationem fuga. Possimus quis earum veniam 
            quasi aliquam eligendi, placeat qui corporis!</div>
        },
        {
            heading: "Terms & Conditions",
            text: <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
            molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
            numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
            optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
            obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
            nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
            tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
            quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos 
            sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
            recusandae alias error harum maxime adipisci amet laborum. Perspiciatis 
            minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit 
            quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur 
            fugiat, temporibus enim commodi iusto libero magni deleniti quod quam 
            consequuntur! Commodi minima excepturi repudiandae velit hic maxime
            doloremque. Quaerat provident commodi consectetur veniam similique ad 
            earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo 
            fugiat, dolorum eligendi quam cupiditate excepturi mollitia maiores labore 
            suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium
            modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam 
            totam ratione voluptas quod exercitationem fuga. Possimus quis earum veniam 
            quasi aliquam eligendi, placeat qui corporis!</div>
        },
        {
            heading: "Add your Marina",
            text: <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
            molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
            numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
            optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis
            obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam
            nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,
            tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,
            quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos 
            sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
            recusandae alias error harum maxime adipisci amet laborum. Perspiciatis 
            minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit 
            quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur 
            fugiat, temporibus enim commodi iusto libero magni deleniti quod quam 
            consequuntur! Commodi minima excepturi repudiandae velit hic maxime
            doloremque. Quaerat provident commodi consectetur veniam similique ad 
            earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo 
            fugiat, dolorum eligendi quam cupiditate excepturi mollitia maiores labore 
            suscipit quas? Nulla, placeat. Voluptatem quaerat non architecto ab laudantium
            modi minima sunt esse temporibus sint culpa, recusandae aliquam numquam 
            totam ratione voluptas quod exercitationem fuga. Possimus quis earum veniam 
            quasi aliquam eligendi, placeat qui corporis!</div>
        },
    ])
    const [selectedOption, setSelectedOption] = useState(selections[0])

    return (
        <div className="alpha-dashboard-main_container">
            <NavBar loaderValue={(data) => setIsLoading(data)} />
            <div className="contact-page-about-container">
                <div className="about-page-about-selection">
                    <div className="about-page-about-selection-heading">About NauticalPay</div>
                    <div className="about-page-about-selection-content-container">
                        <div className="about-page-about-selection-options">
                            {selections.map(selection => <h2 className={selectedOption.heading == selection.heading ? "about-page-about-selected-option" : ""} onClick={() => setSelectedOption(selection)}>{selection.heading}</h2>)}
                        </div>
                        <div className="about-page-about-selection-content">
                            {selectedOption.text}
                        </div>
                    </div>
                </div>
            </div>
            <ContactUs/>
            <AppDownload/>
            <Footer/>
        </div>
    );
}
