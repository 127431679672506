import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { arrowDownGrey, checked, unchecked } from "../../../assets/icons";
import { NavBar, ContactUs, AppDownload, SearchCard, ResultCard, Footer } from "../../../components";
import { FinancingStepFourAdmin, FinancingStepOneAdmin, FinancingStepThreeAdmin, FinancingStepTwoAdmin, Loader, SideBar, SubmitModel, TopBar, BookingCard } from "../../../components";
import './myBookings.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { getTotalAmount } from "../../../globalData";
import { store } from "../../../redux/store";
import useGetDocs from "../../../hooks/useGetDocs";
import { fireDB } from "../../../firebase";

const { db, collection, doc, getDocs, getDoc, query, where } = fireDB

export default function HomePage() {
  const navigate = useNavigate()
  const [currentMenu, setCurrnetMenu] = useState("current")
  const [resutls, setResults] = useGetDocs("Bookings", ["boatId", "==", "@id"])
  const [fullResults, setFullResults] = useState(null)
  const [displayResults, setDisplayResults] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const f = async () => {
      const promises = resutls?.map(async res => {
        const newResult = JSON.parse(JSON.stringify(res))
        const port = await getDoc(doc(db, "Port", res.portId))
        newResult.port = {id: port.id, ...port.data()}
        return newResult
      })
      const fullResults = await Promise.all(promises)
      setFullResults(fullResults)
      setDisplayResults(fullResults.filter(x => 
        currentMenu == "current" ? 
          x.bookingDetail.time.arrivalTime > new Date().getTime() :
          x.bookingDetail.time.arrivalTime <= new Date().getTime()
      ))
      setIsLoading(false)
    }
    f()
  }, [resutls])

  useEffect(() => {
    if(fullResults) setDisplayResults(fullResults.filter(x => 
      currentMenu == "current" ? 
        x.bookingDetail.time.arrivalTime > new Date().getTime() :
        x.bookingDetail.time.arrivalTime <= new Date().getTime()
    ))
  }, [fullResults, currentMenu])

  useEffect(() => console.log("OKKK", fullResults), [fullResults])

  console.log("RESULTSSSS", resutls)
  return (
    <div className="alpha-dashboard-main_container">
      <NavBar loaderValue={(data) => setIsLoading(data)} />
      <div className="bookings-container">
        <Loader loading={isLoading}/>
        <div className="bookings-menu">
          <BookingCard onMenuChange={setCurrnetMenu} options={["Current", "Past"]}/>
        </div>
        <div className="search-results-container">
            {!displayResults?.length && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vh", height: "100%" }}><h2>No Bookings Found</h2></div>}
            {displayResults?.map(doc => ({
              ...doc,
              name: doc.port.portName,
              img: doc.port.PortPictures?.[0],
              desc: (doc.port.discription ?? doc.port.description)?.length > 100 ? (doc.port.discription ?? doc.port.description)?.slice(0, 100) : (doc.port.discription ?? doc.port.description),
              coordinates: [doc.port.lat, doc.port.lng],
              realRate: 26+parseInt(doc.port.ratePerNight) * Math.round((new Date(doc.bookingDetail.time.departureTime).getTime() - new Date(doc.bookingDetail.time.arrivalTime).getTime())/(24*60*60*1000)),
              rate: <div className="rate-container">
                <h2>${26+parseInt(doc.port.ratePerNight) * Math.round((new Date(doc.bookingDetail.time.departureTime).getTime() - new Date(doc.bookingDetail.time.arrivalTime).getTime())/(24*60*60*1000))}</h2>
              </div>
            }))?.map(result => <ResultCard getPort={doc => ({
              ...doc.port,
              name: doc.port.portName,
              img: doc?.img,
              desc: (doc?.port?.discription ?? doc.port.description)?.length > 100 ? (doc.port.discription ?? doc.port.description)?.slice(0, 100) : (doc.port.discription ?? doc.port.description),
              coordinates: [doc.port.lat, doc.port.lng],
              realRate: 26+parseInt(doc.port.ratePerNight) * Math.round((new Date(doc.bookingDetail.time.departureTime).getTime() - new Date(doc.bookingDetail.time.arrivalTime).getTime())/(24*60*60*1000)),
              rate: <div className="rate-container">
                <h2>${26+parseInt(doc?.port?.ratePerNight) * Math.round((new Date(doc?.bookingDetail?.time?.departureTime).getTime() - new Date(doc?.bookingDetail?.time?.arrivalTime).getTime())/(24*60*60*1000))}</h2>
              </div>
            })} result={{...result, rate: null, _: console.log(result)}} image={result?.port?.PortPictures?.[0]} containerClasses="mt-2 justify-content-flex-start" priceClasses="border-price" cardClasses="w-65vw" booked={true} name={result.name} desc={result.desc} coordinates={result.coordinates} rate={result.rate} bookNow={true} moreDetails={false}/>)}
        </div>
      </div>
      <Footer/>
    </div>
  );
}
