import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube, logoSmall, google } from "../../../assets/icons";
import { fireAuth, fireDB } from '../../../firebase'
import { Button, ChoiceInput, TextInput, ImageInput, PasswordInput, TwoScreenLayout } from "../../../components";
import './registerBoat.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { requireNumber } from "../../../globalData";
import { store } from "../../../redux/store";
import SyncLoader from "react-spinners/SyncLoader";

const { instance: auth, useAuthState } = fireAuth
const { db, collection, addDoc, query, where, getDocs } = fireDB


export default function RegisterBoat() {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [name, setName] = useState("")
    const [callSign, setCallSign] = useState("")
    const [license, setLicense] = useState("")
    const [emiratesId, setEmiratesId] = useState("")
    const [passport, setPassport] = useState("")
    const [user, loading, error] = useAuthState(auth)
    const [licenseNumber, setLicenseNumber] = useState("")
    const [serial, setSerial] = useState("")
    const [type, setType] = useState({
        label: "5m",
        value: "5m"
    })
    const [length, setLength] = useState("")
    const [beam, setBeam] = useState("")
    const [draft, setDraft] = useState("")

    const [types, setTypes] = useState([
        {
            label: "5m",
            value: "5m"
        },
        {
            label: "10m",
            value: "10m",
        },
        {
            label: "15m",
            value: "15m",
        },
        {
            label: "20m",
            value: "20m",
        },
        {
            label: "25m",
            value: "25m",
        },
    ])

    useEffect(() => {
        // console.log("XYZ", license, emiratesId, passport)
    }, [license, emiratesId, passport])

    return (
        <TwoScreenLayout containerHeight="230vh" height="230vh" loading={loading} setLoading={setIsLoading}>
            <div className="text-container">
                <h1>Register Your Boat</h1>
            </div>
            <div className="mb-2"></div>
            <TextInput containerStyle="mb-2" label="Boat Name" value={name} onChange={setName}/>
            <TextInput containerStyle="mb-2" label="Serial" value={serial} onChange={setSerial}/>
            <TextInput containerStyle="mb-2" label="Call Sign" value={callSign} onChange={setCallSign}/>
            <TextInput containerStyle="mb-2" label="License Number" value={licenseNumber} onChange={setLicenseNumber}/>
            <ChoiceInput containerStyle="mb-2" label="Type" options={types} value={type} onChange={setType}/>
            <TextInput containerStyle="mb-2" label="Length" value={length} onChange={requireNumber(setLength)}/>
            <TextInput containerStyle="mb-2" label="Beam" value={beam} onChange={requireNumber(setBeam)}/>
            <TextInput containerStyle="mb-2" label="Draft" value={draft} onChange={setDraft}/>
            <ImageInput containerStyle="mb-2" label="License" onChange={setLicense}/>
            <ImageInput containerStyle="mb-2" label="Emirates Id" onChange={setEmiratesId}/>
            <ImageInput containerStyle="mb-2" label="Passport" onChange={setPassport}/>
            <Button type="primary" classes={`w-40 sm-mt-2 mt-3 ${name && beam && licenseNumber && callSign && type && draft && length ? "" : "opacity-disabled"}`} onClick={async () => {
                try {
                    if(!(name && beam && licenseNumber && callSign && type && draft && length && emiratesId && passport && license)) {
                        toast.error("Please fill all fields")
                        return
                    }
                    setIsLoading(true)
                    const userProfileQ = await getDocs(query(collection(db, "profile"), where("userId", '==', user.uid)))
                    let userProfile;
                    userProfileQ.forEach(x => userProfile = x.data())
                    // console.log(userProfile)
                    await addDoc(collection(db, "Boat"), {
                        AboutBoat: {
                            boatName: name,
                            Beam: beam,
                            licenseNumber,
                            callSign,
                            BoatType: type,
                            draft,
                            BoatWeight: length,
                            boatSerial: licenseNumber,
                        },
                        block: false,
                        docs: {
                            EmiratesIDUri: emiratesId,
                            LicenseUri: license,
                            PassportUri: passport,
                        },
                        name: userProfile.name,
                        email: user.email.toLowerCase(),
                        phone: userProfile.phone,
                    })
                    setIsLoading(false)
                    navigate("/")
                    setIsLoading(false)
                } catch (e) {
                    // console.log(e)
                    toast.error("An unexpected error has occured")
                    setIsLoading(false)
                }
            }}>{isLoading ? <SyncLoader color="white"/> : "Continue"}</Button>
            
        </TwoScreenLayout>
    );
}
