import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { fireAuth, fireDB } from "../../../firebase";
import { owner, userIcon } from "../../../assets/icons";
import {
  Button,
  SocialLogin,
  TextInput,
  PasswordInput,
  TwoScreenLayout,
} from "../../../components";
import "./accountType.css";
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";
import { authErrors } from "../../../globalData";
import SyncLoader from "react-spinners/SyncLoader";



export default function AccountType() {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState("");
  const handleClickedOn = (cardType) => {
    setSelectedCard(cardType);
    localStorage.setItem("accountType", cardType);
    navigate("/register");
  };

  return (
    <TwoScreenLayout height="130vh">
      <div className="text-container w-72p justify-content-flex-start">
        <h1>Select Account Type </h1>
      </div>
      <div className="mainContainer">
        <div className={`card ${selectedCard === "user" ? "selected" : ""}`} onClick={() => handleClickedOn("user")}>
          <div className="card-container">

              <img src={userIcon} className="icon-container" />
            <div className="userDetail">
              <div className="iAmUser">I am User</div>
              <div className="loremIpsumDolorSitAmetConsecte">
                Lorem ipsum dolor sit amet, consectetur adipi elit eclipe
              </div>
            </div>
          </div>
        </div>
        <div className={`card ${selectedCard === "owner" ? "selected" : ""}`} onClick={() => handleClickedOn("owner")}>
          <div className="card-container">
              <img src={owner} className="icon-container" />
            <div className="userDetail">
              <div className="iAmUser">Port Owner</div>
              <div className="loremIpsumDolorSitAmetConsecte">
                Lorem ipsum dolor sit amet, consectetur adipi elit eclipe
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="continue-as-guest">
        <span>Just Exploring?</span>
        <span
          className="color-blue"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          Continue as Guest
        </span>
      </div>
      <Button 
      onClick={() => {
        if (selectedCard === "user") {
          navigate("/register");
        } else if (selectedCard === "owner") {
          navigate("/AddPort");
        } else {
          toast.error("Please select an account type");
        }
      }}
      type="primary" classes="w-50p sm-mt-2 sm-mb-2">Register</Button>
    </TwoScreenLayout>
  );
}

