import React from "react";
import { Ellipse } from "../../assets/icons";

const BCard = ({
  username,
  durationFrom,
  durationTo,
  totalAmount,
  current,
}) => {
  return (
    <div style={cardStyle}>
      <div style={avatarStyle}>
        <img src={Ellipse} alt="profile" style={profileImageStyle} />
        <h3 style={nameStyle}>{username}</h3>
      </div>
      <div style={detailsStyle}>
        <h4 style={nameStyle}>Duration</h4>
        <div style={detailContainer}>
          <div>
            <h2 style={fromTitle}>From:</h2>
            <h2 style={durationFromTitle}>{durationFrom}</h2>
          </div>
          <div>
            <h2 style={fromTitle}>To:</h2>
            <h2 style={durationFromTitle}>{durationTo}</h2>
          </div>
        </div>
      </div>
      <div style={detailsStyle}>
        <div style={detailContainer}>
          <h3 style={nameStyle}>Total Amount</h3>
          <h3 style={fromTitle1}>{totalAmount}</h3>
        </div>
      </div>
      {current && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button style={buttonStyle}>Cancel</button>
        </div>
      )}
    </div>
  );
};

const cardStyle = {
  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  padding: "30px",
  margin: "10px",
  width: "300px",
};

const avatarStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const nameStyle = {
  color: "#000000",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "20px",
};

const profileImageStyle = {
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  objectFit: "cover",
};

const detailsStyle = {
  borderTop: "1px solid #ccc",
  padding: "10px 0",
  margin: "10px 0",
  marginTop: "20px",
};

const detailContainer = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "10px",
};

const durationFromTitle = {
  color: "#000000",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
};

const fromTitle = {
  color: "#cccccc",
  fontWeight: "700",
  fontSize: "12px",
  lineHeight: "20px",
};
const fromTitle1 = {
    color: "#376FCC",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
  };

const buttonStyle = {
  backgroundColor: "#376FCC",
  color: "#fff",
  border: "none",
  borderRadius: "50px",
  padding: "10px 50px",
  cursor: "pointer",
  outline: "none",
  textAlign: "center",
};

export default BCard;
