import React, { useState } from 'react'
import './choiceInput.css'

const caretIcon = "https://www.pngall.com/wp-content/uploads/6/Caret-PNG-File.png"

export default function ChoiceInput(props) {
    const [clicked, setClicked] = useState(false)

    return (
        <div className='choice-input'>
            <label>{props.label}</label>
            <div className={`choice-input-clickable ${props.containerStyle}`} onClick={() => setClicked(!clicked)}>
                {props.value.label}
                <img src={caretIcon} />
            </div>
            {clicked && <div className='choice-input-options'>
                {props.options.map(opt => <>
                    <p style={{ width: "94%", cursor: "pointer" }}onClick={() => {
                        (props.onChange ?? (() => {}))(opt)
                        setClicked(!clicked)
                    }}>{opt.label}</p>
                    <div className='separator-choice'></div>
                </>)}
            </div>}
        </div>
    )
}