import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube } from "../../../assets/icons";
import { NavBar, AppDownload, ContactUs, Footer } from "../../../components";
import './notFound.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";

export default function NotFound() {
    const [isLoading, setIsLoading] = useState(false)
    
    return (
        <div className="alpha-dashboard-main_container">
            <NavBar loaderValue={(data) => setIsLoading(data)} />
            <div className="not-found-container">
                <h1>Work In Progress!</h1>
            </div>
            <Footer />
        </div>
    );
}
