import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube } from "../../../assets/icons";
import { NavBar, ContactUs, AppDownload, SearchCard, ResultCard, Footer } from "../../../components";
import { FinancingStepFourAdmin, FinancingStepOneAdmin, FinancingStepThreeAdmin, FinancingStepTwoAdmin, Loader, SideBar, SubmitModel, TopBar } from "../../../components";
import './locations.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";
import useGetDocs from "../../../hooks/useGetDocs";

const OverviewPoint = ({number, title, desc}) => <div className="overview-point">
  <div className="overview-point-number">{number}</div>
  <div className="overview-point-title">{title}</div>
  <div className="overview-point-desc">{desc}</div>
</div>

export default function HomePage() {
  const navigate = useNavigate()
  const [location, setLocation] = useState("")
  const [coordinates, setCoordinates] = useState("")
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [resutls, setResults, loading] = useGetDocs("Port", ["name", "!=", ""])

  return (
    <div className="alpha-dashboard-main_container">
      <NavBar/>
      <Loader loading={loading}/>
      <div className="locations-container">
        <SearchCard cardClasses="locations-custom-shadow" searchButton={true} location={location} coordinates={coordinates} date={date} time={time} setLocation={setLocation} setCoordinates={setCoordinates} setDate={setDate} setTime={setTime} />
        <div className="search-results-container">
            {resutls?.map(doc => ({
              ...doc,
              name: doc.portName,
              desc: (doc.description ?? doc.discription).length > 100 ? (doc.description ?? doc.discription).slice(0, 100) : (doc.description ?? doc.discription),
              coordinates: [doc.lat, doc.lng],
              realRate: doc.ratePerNight,
              rate: <div className="rate-container">
                <h2>${doc.ratePerNight.slice(0, 4)}/</h2>
                <p>night</p>
              </div>
            })).map(result => <ResultCard getPort={x => x} containerClasses="mt-2" result={{...result, rate: null}} image={result?.PortPictures?.[0]} name={result?.name} desc={result?.desc} coordinates={result?.coordinates} rate={result?.rate} bookNow moreDetails />)}
        </div>
      </div>
      <Footer/>
    </div>
  );
}
