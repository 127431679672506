import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: '',
};

export const portSlice = createSlice({
    name: 'port',
    initialState,
    reducers: {
        setPortValue: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setPortValue } = portSlice.actions;

export default portSlice.reducer;