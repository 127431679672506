import React, { useState , useEffect } from "react";
import "./portContent.css";
import {
  fluentBeach,
  editIcon,
  locationoutline,
  beach,
  priceTag,
  photoselect,
  uploadIcon,
} from "../../../assets/icons";
import { setPortValue } from "../../../redux/Slices/portSlice";
import { fireAuth, fireDB, fireStorage } from "../../../firebase";
import useGetDocs from "../../../hooks/useGetDocs";
import ImageUploading from "react-images-uploading";
import { useDispatch , useSelector } from "react-redux";

const PortInfo = ({ title, description, icon, altText }) => (
  <div className="demo-container">
    <div className="inner-container">
      <div className="inner-container-left">
        <img src={icon} alt={altText} className="icon-size" />
        <div className="left-container-header">
          <h4 className="left-title">{title}</h4>
          <h4 className="left-desc">{description}</h4>
        </div>
      </div>
      <div className="inner-container-right">
        <img src={editIcon} alt="edit-icon" className="icon-size " />
      </div>
    </div>
  </div>
);

export default function PortContent({ports}) {
  const [images, setImages] = useState([]);
  const { instance: auth, useAuthState } = fireAuth;
  const user = auth?.currentUser;

  


  const maxNumber = 69;

  return (
    <div className="port-content">
      {ports?.map((port) => (
        <div className="port-container" key={port.id}>
          <div className="port-header">
            <h1>{port.name}</h1>
          </div>
          <div className="main-port-container">
            <PortInfo
              title="Port Name"
              description={port.portName}
              icon={fluentBeach}
              altText="fluent-beach"
            />
            <PortInfo
              title="Location"
              description={port.Location}
              icon={locationoutline}
              altText="location-outline"
            />
          </div>
          <div className="main-port-container">
            <PortInfo
              title="Coordinates"
              description={`Lat: ${port.lat}, Lng: ${port.lng}`}
              icon={beach}
              altText="beach"
            />
            <PortInfo
              title="Rate Per Night"
              description={`$ ${port.ratePerNight}`}
              icon={priceTag}
              altText="price-tag"
            />
          </div>
          <div className="main-port-container">
            <div className="third-container-port">
              <div className="inner-container">
                <div className="photo-container">
                  <img src={photoselect} alt="photo-select" className="icon-size" />
                  <h4 className="left-title">Photos</h4>
                </div>
              </div>
            </div>
            <PortInfo
              title="Description"
              description={port.discription}
              icon={beach}
              altText="location-outline"
            />
          </div>
          <div className="image-container">
            <ImageUploading
              multiple
              value={port.PortPictures.map((url) => ({ data_url: url }))}
              onChange={() => {}}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({ imageList, onImageUpload }) => (
                <div className="upload-image-warpper">
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image["data_url"]} alt="" className="image" />
                    </div>
                  ))}
                  <img src={uploadIcon} alt="upload" onClick={onImageUpload} className="image" />
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
      ))}
    </div>
  );
}
