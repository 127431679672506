import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import { apple,facebook, google } from "../../../assets/icons";
import { Button, SocialLogin, TextInput, PasswordInput, TwoScreenLayout } from "../../../components";
import { fireAuth, fireDB } from '../../../firebase'
import './register.css'
import { upload, uploadTwo } from "../../../helpingMethods";
import { api } from "../../../network/Environment";
import { Method, callApi } from "../../../network/NetworkManger";
import { useSnackbar } from "react-simple-snackbar";
import { snakbarOptions } from "../../../globalData";
import { store } from "../../../redux/store";
import { authErrors } from "../../../globalData"
import SyncLoader from "react-spinners/SyncLoader";

const { instance: auth, createUserWithEmailAndPassword, useAuthState, signInWithEmailAndPassword } = fireAuth
const { collection, addDoc, db } = fireDB

const logIn = async (setIsLoading, navigate, data, logInFunction=async()=>null) => {
    setIsLoading(true)
    try {
        const {user} = await logInFunction()
        const getAccountType = localStorage.getItem('accountType')
        await addDoc(collection(db, "profile"), {...data, userId: user.uid, email: user.email ?? data.email , accountType: getAccountType})
        // const user = await createUserWithEmailAndPassword(auth, email.toLowerCase(), password)
        // await addDoc(collection(db, "profile"), {
        //     userId: user.user.uid,
        //     name,
        //     phone,
        // })
        if(getAccountType === "user") {
        navigate("/registerBoat")
        } else if(getAccountType === "owner") {
            navigate("/AddPort");
        }
        
        setIsLoading(false)
    } catch (e) {
        console.log(e)
        toast.error(authErrors[e.code.replace("auth/", "")] ?? "An unexpected error has occured")
        setIsLoading(false)
    }
}

export default function HomePage() {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [user, loading, error] = useAuthState(auth)

    return (
        <TwoScreenLayout height="130vh">
            <div className="text-container w-72p justify-content-flex-start">
                <h1>Register</h1>
            </div>

            <div className="social-button">
            <SocialLogin label="Google" icon={google} classes="sm-none" onLogin={res => logIn(setIsLoading, navigate, { name, email, phone }, async () => res) }/>
            <SocialLogin label="Apple" icon={apple} classes="sm-none" onLogin={res => logIn(setIsLoading, navigate, { name, email, phone }, async () => res) }/>
            <SocialLogin label="Facebook" icon={facebook} classes="sm-none" onLogin={res => logIn(setIsLoading, navigate, { name, email, phone }, async () => res) }/>
            </div>

            <div className="separator mb-2"></div>
            <TextInput containerStyle="mb-2 register-input-container" inputStyle="register-input" label="Name*" value={name} onChange={setName}/>
            <TextInput containerStyle="mb-2 register-input-container" inputStyle="register-input" label="Email*" value={email} onChange={setEmail}/>
            <TextInput containerStyle="mb-2 register-input-container" inputStyle="register-input" label="Phone Number" value={phone} onChange={setPhone}/>
            <TextInput containerStyle="mb-2 register-input-container" inputStyle="register-input" label="Password" value={password} onChange={setPassword}/>
            <div className="have-an-account-prompt">
                <span>Already have an account?</span>
                <span className="color-blue" style={{ cursor: 'pointer' }} onClick={() => navigate("/login")}>Login</span>
            </div>
            <div className="continue-as-guest">
                <span>Just Exploring?</span>
                <span className="color-blue" style={{ cursor: 'pointer' }} onClick={() => navigate("/")}>Continue as Guest</span>
            </div>
            <Button disabled={!name || !email || !password} type="primary" classes="w-72p sm-mt-2 sm-mb-2" onClick={() => logIn(setIsLoading, navigate, { name, email, phone }, () => createUserWithEmailAndPassword(auth, email.toLowerCase(), password)) }>{isLoading ? <SyncLoader color="white"/> : "Register"}</Button>
            <SocialLogin label="Google" icon={google} classes="sm-only-flex" onLogin={res => {
                logIn(setIsLoading, navigate, { name, email, phone }, async () => res)
            }}/>
            <SocialLogin label="Apple" icon={apple} classes="sm-only-flex" onLogin={res => {
                logIn(setIsLoading, navigate, { name, email, phone }, async () => res)
            }}/>
            <SocialLogin label="Facebook" icon={facebook} classes="sm-only-flex" onLogin={res => {
                logIn(setIsLoading, navigate, { name, email, phone }, async () => res)
            }}/>
        </TwoScreenLayout>
    );
}
