import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logo, location, calendar, play, apple, clock, homeBoats, search, book, enjoy, homeShips, arrow, ticketBlue, callBlue, locationBlue, facebook, instagram, linkedin, twitter, youtube, logoSmall, google } from "../../assets/icons";
import { Loader } from "../../components";

export default function TwoScreenLayout(props) {
    const navigate = useNavigate()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")

    return (
        <div className="alpha-dashboard-main_container">
            <Loader loading={props.isLoading}/>
            <div className="register-page-register-container" style={{ height: props.containerHeight ?? "130vh" }}>
                <div className="register-page-register-logo-container">
                    <div className="register-page-register-logo">
                        <img src={logoSmall} />
                        <p>NauticalPay</p>
                    </div>
                    <p>Hassle-Free Marina Bookings</p>
                </div>
                <div className="register-page-register-form-container" style={{ height: props.height ?? "100vh", marginTop: "0px" }}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
